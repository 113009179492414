import React, { useEffect } from "react";
import { EmptyProps, RaffleBasket, UserRedirect } from "types";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearRaffleBasket,
  selectRaffleBasket,
} from "../../features/basket/basketSlice";
import {
  getRaffleInfo,
  selectRaffleData,
  selectRaffleInfo,
} from "../../features/raffles/rafflesSlice";
import Loader from "../Loader";

const WithRaffleInfo: React.FC<EmptyProps> = () => {
  const { raffleId } = useParams();

  const raffleInfo = useAppSelector((state) =>
    selectRaffleInfo(state, raffleId || "")
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    let t = 0;
    if (raffleId) {
      dispatch(
        getRaffleInfo({
          raffleId,
          page: 0,
        })
      );
      t = window.setInterval(() => {
        dispatch(
          getRaffleInfo({
            raffleId,
            page: 0,
          })
        );
      }, 5000);
    }
    return () => {
      clearInterval(t);
    };
  }, [raffleId]);

  if (!raffleInfo) {
    return <Loader />;
  }
  return <Outlet />;
};

export default WithRaffleInfo;
