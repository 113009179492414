import Popup from "../components/Popup";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import {
  addRandomTicket,
  clearRaffleBasket,
  clearTicketNumbers,
  randomizeAllTickets,
  randomizeTicket,
  removeLastTicket,
  removeTicket,
  selectTicketNumber,
} from "../features/basket/basketSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { BasketTicketProp } from "types";
import classNames from "classnames";
import useBasket from "../hooks/useBasket";
import CurrentRaffleTopBanner from "../components/CurrentRaffleTopBanner";
import { useInView } from "react-intersection-observer";
import AmountView, { formatValue } from "../components/common/AmountView";
import ValidationError from "../components/ValidationError";
import useValidationError from "../hooks/useValidationError";
import { useTranslation } from "react-i18next";
import { selectUserCurrencyTemplate } from "../features/user/userSlice";
type Props = {};

const Tickets: React.FC<Props> = () => {
  const { raffleTickets, raffleId, raffleData } = useBasket();

  const [inViewTicketNum, setInView] = useState(-999);
  const { message, setMessage } = useValidationError();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const allTicketsAreValid = useMemo(() => {
    return raffleTickets.tickets.every((s) => s.numbers.length === 6);
  }, [raffleTickets]);

  function handleSubmitTickets() {
    if (!allTicketsAreValid)
      setMessage(
        `Your purchase of ${raffleTickets.tickets.length} ticket you eligible to enter the Raffle Draw. Choose 6 numbers in each line to participate.`
      );
    else navigate(`/raffles/${raffleId}/collections`);
  }
  function addTicket() {
    dispatch(
      addRandomTicket({
        raffleId,
      })
    );
  }
  function removeTicket() {
    dispatch(
      removeLastTicket({
        raffleId,
      })
    );
  }
  function handleRandomizeAllTickets() {
    dispatch(
      randomizeAllTickets({
        raffleId,
        numberCount: raffleData?.numberCount || 0,
      })
    );
  }
  function clearAllLines() {
    dispatch(
      clearRaffleBasket({
        raffleId,
      })
    );
  }

  function scrollToTicket(number: number) {
    let el = document.getElementById("raffle-ticket-" + number);
    if (el)
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
  }

  if (!raffleData) return <Navigate to={"/home"} />;

  return (
    <div className="ticket-template-wrp">
      {/*<Popup/>*/}
      <CurrentRaffleTopBanner data={raffleData} />

      <div className="container">
        <div className="ticket-t-back-wrp">
          <div className="ticket-t-h-left">
            <div className="ticket-t-back-block">
              <div className="ticket-t-back-block-inner">
                <i className="icon-arrow-left" />
                <NavLink to={"/home"} className={"ticket-t-back-txt"}>
                  {t("Back")}
                </NavLink>
                {/*<p className="ticket-t-back-txt">back</p>*/}
              </div>
            </div>
            <p className="buy-one-dig-txt">
              {t("Select six numbers on each ticket")}
              {/*<span><AmountView value={raffleData.ticketPrice}/></span>*/}
            </p>
          </div>
          <div className="ticket-t-h-right">
            <div className="ticket-t-btn-wrp">
              <div className="ticket-t-btn-clear-inner">
                {/*Miro jan es arajin button@*/}
                <button
                  className="btn-bottom type-1"
                  onClick={handleRandomizeAllTickets}
                >
                  {t("Randomise all lines")}
                </button>
                <button className="btn-bottom type-1" onClick={clearAllLines}>
                  <i className="icon-close-n" /> {t("Clear all lines")}
                </button>
              </div>
              {/*<button className="btn-bottom type-2">Next</button>*/}
              <button
                type="button"
                onClick={handleSubmitTickets}
                className={classNames("btn-bottom type-2")}
              >
                {t("Next")}
              </button>
            </div>
            <div className="ticket-t-add-t">
              <p className="add-ticket-txt">{t("Add Ticket")}</p>
              <div className="add-ticket-calc-wrp">
                <button className="add-ticket-calc-btn" onClick={removeTicket}>
                  -
                </button>
                <span className="add-ticket-number-block">
                  {raffleTickets.tickets.length}
                </span>
                <button className="add-ticket-calc-btn" onClick={addTicket}>
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <ValidationError message={message} />
        <div className="mob-slider-pagination-wrp">
          <p className="mob-slider-pag-txt">{t("Tickets")}:</p>
          <div className="mob-slider-pag-block">
            {raffleTickets.tickets.map((rt, idx) => (
              <div
                onClick={() => scrollToTicket(idx + 1)}
                key={idx}
                className={classNames("mob-slider-pag", {
                  active: inViewTicketNum === idx + 1,
                })}
              >
                <span>{idx + 1}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="tick-t-holder">
          {/*<div className="tick-slider-icon-wrp">*/}
          {/*    <div className="tick-slider-icon"><i className="icon-arrow-left"/></div>*/}
          {/*    <div className="tick-slider-icon"><i className="icon-arrow-right"/></div>*/}
          {/*</div>*/}
          {raffleTickets.tickets.map((raffleTicket, idx) => (
            <RaffleBasketTicket
              setVisible={setInView}
              number={idx + 1}
              data={raffleTicket}
              raffleId={raffleId}
              raffleData={raffleData}
              unlocked={true}
              key={idx}
            />
          ))}
          <RaffleBasketTicket
            setVisible={setInView}
            number={-1}
            raffleId={raffleId}
            raffleData={raffleData}
            data={{ numbers: [] }}
            unlocked={false}
            key="empty"
          />
        </div>
        <button
          type="button"
          onClick={handleSubmitTickets}
          className={classNames("btn-ticket-next-mobile")}
        >
          <div className="btn-bottom type-2">Next</div>
        </button>
        <div className="ticket-t-details-wrp" style={{ display: "none" }}>
          <div className="ticket-t-details-clm">
            <p className="ticket-t-details-clm-title">ticket details</p>
            <div className="ticket-d-icon-wrp">
              <div className="ticket-d-icon-holder">
                <i className="icon-tiiick-1" />
              </div>
              <div className="ticket-d-icon-txt">Ticket 1</div>
            </div>
          </div>
          <div className="ticket-t-details-clm center">
            <p className="ticket-t-details-clm-title">Selected numbers</p>
            <div className="ticket-t-details-circle-wrp">
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">1</span>
              </div>
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">1</span>
              </div>
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">6</span>
              </div>
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">6</span>
              </div>
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">6</span>
              </div>
              <div className="tick-t-block-m-circle active">
                <div className="ticket-t-details-circle-close">
                  <i className="icon-close-n" />
                </div>
                <span className="tick-t-block-m-number">9</span>
              </div>
            </div>
          </div>
          <div className="ticket-t-details-clm right">
            <p className="ticket-t-details-clm-title">Edit ticket</p>
            <div className="ticket-t-details-delete">
              <i className="icon-delete" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RaffleBasketTicket: React.FC<BasketTicketProp> = ({
  unlocked,
  data,
  number,
  raffleId,
  setVisible,
  raffleData,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const template = useAppSelector(selectUserCurrencyTemplate);
  function handleRandomButtonClick() {
    dispatch(
      randomizeTicket({
        raffleId,
        ticketNumber: number,
        numberCount: raffleData.numberCount,
      })
    );
  }
  function handleRemoveTicketClick() {
    dispatch(
      removeTicket({
        raffleId,
        ticketNumber: number,
      })
    );
  }

  function handleUnlockClick() {
    dispatch(
      addRandomTicket({
        raffleId,
      })
    );
  }
  const numbers = useMemo(() => {
    return new Array(raffleData.numberCount)
      .slice()
      .fill(0)
      .map((a, b) => b + 1);
  }, [raffleData.numberCount]);
  const { count, selection } = useMemo(() => {
    let selection = [] as { value: number; selected: boolean }[];
    let count = 0;
    numbers.forEach((n) => {
      let selected = data.numbers.includes(n);
      selected && count++;
      selection.push({
        value: n,
        selected,
      });
    });

    return {
      count,
      selection,
    };
  }, [data.numbers, numbers]);

  function handleCellClick(value: number) {
    dispatch(
      selectTicketNumber({
        raffleId,
        selectedNumber: value,
        ticketNumber: number,
        selectedCount: raffleData.selectedCount,
      })
    );
  }
  function handleClearTicketNumbers() {
    dispatch(
      clearTicketNumbers({
        raffleId,
        ticketNumber: number,
      })
    );
  }

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  });
  useEffect(() => {
    if (inView && number > -1) {
      setVisible(number);
    }
  }, [inView, number]);
  return (
    <div
      onClick={unlocked ? undefined : handleUnlockClick}
      ref={ref}
      className={classNames("tick-t-block", unlocked ? "active" : "unlock")}
      data-unlock={
        unlocked
          ? ""
          : t("Click to unlock", {
              value: formatValue(raffleData.ticketPrice, template),
            })
      }
      id={`raffle-ticket-${number}`}
    >
      {number > 1 ? (
        <button
          className="ticket-close-button"
          onClick={handleRemoveTicketClick}
        >
          <i className="icon-close-n" />
        </button>
      ) : null}
      <div className="tick-t-block-top">
        <div className="tick-block-top-txt-wrp">
          <p className="tick-block-top-txt">
            {t("ticket")} {number > 0 ? number : ""}
          </p>
          <div
            className={classNames(
              "tick-block-top-txt-icon",
              "test",
              count === 6 ? "check" : "warning"
            )}
          >
            {/*add/remove check,warning*/}
            <i className="icon-claimed-fill" />
          </div>
        </div>
        <div className="tick-block-top-sel-number">
          {/*<p className="tick-block-top-snake-txt">Snake 007</p>*/}
          <p className="tick-block-top-snake-txt right">
            {t("Selected Numbers")}: {count}/6
          </p>
        </div>
      </div>
      <div className="tick-t-block-middle">
        {selection.map((sel) => (
          <div
            onClick={() => handleCellClick(sel.value)}
            className={classNames("tick-t-small-circle", {
              active: sel.selected,
            })}
            key={sel.value}
          >
            <span>{sel.value}</span>
          </div>
        ))}
      </div>
      <div className="tick-t-block-bottom">
        <div className="tick-t-block-b-left">
          <button className="ticket-btn" onClick={handleRandomButtonClick}>
            {t("Random")}
          </button>
        </div>
        <div
          className="tick-t-block-b-right"
          onClick={handleClearTicketNumbers}
        >
          <i className="icon-refresh" />
        </div>
      </div>
    </div>
  );
};

export default Tickets;
