function noop(methodName) {
    return ()=> {
        throw new Error("method " + methodName + " not found in window.external api")
    }
}

if(!window.external)
    window.external = {}

export default {
    enableMoneyInput:window.external.enableMoneyInput || noop('enableMoneyInput'),
    disableMoneyInput:window.external.disableMoneyInput || noop('disableMoneyInput'),
    initStartSettings:window.external.initStartSettings || noop('initStartSettings'),
    printBase64Ticket:window.external.printBase64Ticket  || noop('printBase64Ticket'),
    openConsole:window.external.openConsole  || noop('openConsole'),
    showTapPage:window.external.showTapPage  || noop('showTapPage'),
}