import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'


i18n.use(HttpApi).use(initReactI18next)

let loadPath = process.env.NODE_ENV === 'production' ?
    `/static/translations/{{lng}}.json?`+(+new Date())
    :`/static/translations/{{lng}}.json?`+(+new Date());
export function init() {
  let lng = new URLSearchParams(window.location.search).get('lang') || 'en';
  return i18n.init({
    lng,
    fallbackLng: 'en',
    // debug: true,
    backend: {
      loadPath
    },
    saveMissing:true,
    missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, options){
      console.warn(lngs,key)
    }

  })
}

export function changeLanguage(ln :string) {
  return i18n.changeLanguage(ln).then(()=>{
    console.log("LNG changed")
  })
}