import React from "react";

export type ValidationErrorProps = {
    message: string
}
const ValidationError: React.FC<ValidationErrorProps> = ({message}) => {
    if(!message)
        return null;
    return <div className="ticket-draw-txt">
                    <span>
                        {message}
                    </span>
    </div>
}

export default ValidationError