import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { addTickets, checkTransaction } from "../../api/rafflesApi";
import {
  AddRaffleData,
  BasketState,
  BasketTicket,
  NftCard,
  Raffle,
  RaffleBasket,
} from "types";
import shuffle from "lodash/shuffle";
import { showToast } from "../../components/ToastContainer";
import { getRafflesASync } from "../raffles/rafflesSlice";

export const addTicketAsync = createAsyncThunk(
  "basket/addTickets",
  async (data: AddRaffleData) => {
    const response = await addTickets(data);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const checkTransactionAsync = createAsyncThunk(
  "basket/checkTransaction",
  async (raffleId: number, thunkAPI) => {
    let rootState = thunkAPI.getState() as RootState;

    let raffleTickets = selectRaffleBasket(rootState, raffleId.toString());

    if (!raffleTickets.pendingTrxId) return;

    const response = await checkTransaction(raffleTickets.pendingTrxId);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const initialState: BasketState = {
  status: "idle",
  tickets: {},
};
export const basketSlice = createSlice({
  name: "basket",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addTicket: (
      state,
      action: PayloadAction<BasketTicket & { raffleId: number }>
    ) => {
      const raffleBasket = state.tickets[action.payload.raffleId];
      if (raffleBasket) {
        raffleBasket.tickets = [action.payload];
      } else {
        state.tickets[action.payload.raffleId] = {
          tickets: [action.payload],
          nfts: [],
          raffleId: action.payload.raffleId,
          status: "pending",
          raffleNfts: [],
        };
      }
    },
    randomizeTicket: (
      state,
      action: PayloadAction<{
        raffleId: number;
        numberCount: number;
        ticketNumber: number;
      }>
    ) => {
      let ticket =
        state.tickets[action.payload.raffleId]?.tickets[
          action.payload.ticketNumber - 1
        ];
      if (ticket) {
        ticket.numbers = getRandomNumbers(action.payload.numberCount);
      }
    },
    removeTicket: (
      state,
      action: PayloadAction<{ raffleId: number; ticketNumber: number }>
    ) => {
      let ticket =
        state.tickets[action.payload.raffleId]?.tickets[
          action.payload.ticketNumber - 1
        ];
      if (ticket) {
        state.tickets[action.payload.raffleId].tickets.splice(
          action.payload.ticketNumber - 1,
          1
        );
        state.tickets[action.payload.raffleId].nfts.splice(
          action.payload.ticketNumber - 1,
          1
        );
      }
    },
    selectTicketNumber: (
      state,
      action: PayloadAction<{
        raffleId: number;
        ticketNumber: number;
        selectedNumber: number;
        selectedCount: number;
      }>
    ) => {
      let ticket =
        state.tickets[action.payload.raffleId]?.tickets[
          action.payload.ticketNumber - 1
        ];
      if (ticket) {
        let idx = ticket.numbers.findIndex(
          (n) => n === action.payload.selectedNumber
        );
        if (idx > -1) ticket.numbers.splice(idx, 1);
        else if (ticket.numbers.length < action.payload.selectedCount) {
          //selectedCount
          ticket.numbers.push(action.payload.selectedNumber);
        }
      }
    },
    clearTicketNumbers: (
      state,
      action: PayloadAction<{ raffleId: number; ticketNumber: number }>
    ) => {
      let ticket =
        state.tickets[action.payload.raffleId]?.tickets[
          action.payload.ticketNumber - 1
        ];
      if (ticket) {
        ticket.numbers = [];
      }
    },
    clearTicketByNumber: (
      state,
      action: PayloadAction<{ raffleId: number; ticketNumber: number }>
    ) => {
      let basket = state.tickets[action.payload.raffleId];
      if (basket && basket.tickets[action.payload.ticketNumber - 1]) {
        basket.tickets.splice(action.payload.ticketNumber - 1, 1);
        basket.nfts.splice(action.payload.ticketNumber - 1, 1);
      }
    },
    randomizeAllTickets: (
      state,
      action: PayloadAction<{ raffleId: number; numberCount: number }>
    ) => {
      let tickets = state.tickets[action.payload.raffleId]?.tickets;
      if (tickets) {
        tickets.forEach(
          (t) => (t.numbers = getRandomNumbers(action.payload.numberCount))
        );
      }
    },
    clearRaffleBasket: (state, action: PayloadAction<{ raffleId: number }>) => {
      delete state.tickets[action.payload.raffleId];
    },
    stripePaymentSuccess: (
      state,
      action: PayloadAction<{ raffleId: number }>
    ) => {
      state.tickets[action.payload.raffleId].status = "paid";
    },
    clearBasketError: (state, action: PayloadAction<{ raffleId: number }>) => {
      state.tickets[action.payload.raffleId].status = "pending";
      state.tickets[action.payload.raffleId].errorMessage = "";
      delete state.tickets[action.payload.raffleId].pendingTrxId;
    },
    selectNFT: (
      state,
      action: PayloadAction<NftCard & { raffleId: number }>
    ) => {
      let nfts = state.tickets[action.payload.raffleId]?.nfts;
      let tickets = state.tickets[action.payload.raffleId]?.tickets;

      if (nfts) {
        let existingNftIdx = nfts.findIndex((n) => n.id === action.payload.id);
        if (existingNftIdx > -1) {
          nfts.splice(existingNftIdx, 1);
        } else if (nfts.length < tickets.length) {
          nfts.push(action.payload);
        }
      }
    },
    setTicketNfts: (
      state,
      action: PayloadAction<{ raffleId: number; nfts: NftCard[] }>
    ) => {
      if (state.tickets[action.payload.raffleId]) {
        state.tickets[action.payload.raffleId].nfts = action.payload.nfts;
      }
    },
    addRandomTicket: (state, action: PayloadAction<{ raffleId: number }>) => {
      if (!state.tickets[action.payload.raffleId])
        state.tickets[action.payload.raffleId] = {
          tickets: [],
          raffleId: action.payload.raffleId,
          nfts: [],
          status: "pending",
          raffleNfts: [],
        };

      if (state.tickets[action.payload.raffleId].tickets.length < 10) {
        state.tickets[action.payload.raffleId].tickets.push({
          numbers: [],
        });
      } else {
        showToast("warning", "maximum allowed tickets are 10");
      }
    },
    removeLastTicket: (state, action: PayloadAction<{ raffleId: number }>) => {
      if (state.tickets[action.payload.raffleId]) {
        state.tickets[action.payload.raffleId].tickets.length--;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addTicketAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(checkTransactionAsync.fulfilled, (state, action) => {
        if (action.payload.code !== 7) {
          state.tickets[action.meta.arg].status = "paid";
          state.tickets[action.meta.arg].errorMessage = "";
        }
      })
      .addCase(checkTransactionAsync.rejected, (state, action) => {
        state.tickets[action.meta.arg].status = "error";
        state.tickets[action.meta.arg].errorMessage =
          action.error.message || "Failed to process. Try again later";
      })
      .addCase(addTicketAsync.fulfilled, (state, action) => {
        if (action.payload.payment.payStatus === 2) {
          state.tickets[action.payload.trxInfo.raffleId].status =
            "pending-payment";
          state.tickets[action.payload.trxInfo.raffleId].pendingTrxId =
            action.payload.trxInfo.trxId;
        } else if (action.payload.payment.payStatus === 0) {
          state.tickets[action.payload.trxInfo.raffleId].status = "error";
          state.tickets[action.meta.arg.raffleId].errorMessage =
            "Failed to process. Try again later";
        } else if (action.payload.payment.payStatus === 3) {
        } else {
          state.tickets[action.payload.trxInfo.raffleId].status = "paid";
          state.tickets[action.payload.trxInfo.raffleId].pendingTrxId =
            action.payload.trxInfo.trxId;
        }
      })
      .addCase(addTicketAsync.rejected, (state, action) => {
        state.tickets[action.meta.arg.raffleId].status = "error";
        state.tickets[action.meta.arg.raffleId].errorMessage =
          action.error.message;

        console.error(action.error);
      })
      .addCase(getRafflesASync.fulfilled, (state, action) => {
        let raffleIds = action.payload.raffles
          .filter((r: Raffle) => r.status === 1)
          .map((r: Raffle) => r.id);

        Object.keys(state.tickets)
          .map((key) => parseInt(key))
          .forEach((rId) => {
            if (!raffleIds.includes(rId)) {
              delete state.tickets[rId];
            }
          });
      });
  },
});

export const {
  clearRaffleBasket,
  selectTicketNumber,
  clearTicketNumbers,
  clearTicketByNumber,
  stripePaymentSuccess,
  clearBasketError,
  setTicketNfts,
  removeTicket,
  addRandomTicket,
  removeLastTicket,
  randomizeTicket,
  randomizeAllTickets,
  selectNFT,
} = basketSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBasket = (state: RootState) => state.basket;
export const selectRaffleBasket = (state: RootState, raffleId: string) => {
  return raffleId && state.basket.tickets[raffleId]
    ? state.basket.tickets[raffleId]
    : ({
        raffleId: raffleId || 0,
        nfts: [],
        tickets: [],
        status: "pending",
        raffleNfts: [],
      } as RaffleBasket);
};

function getRandomNumbers(numberCount: number) {
  return shuffle(new Array(numberCount).fill(0).map((a, b) => b + 1)).slice(
    0,
    6
  );
}

export default basketSlice.reducer;
