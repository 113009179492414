import { EmptyProps } from "types";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getUserAsync, selectUser } from "../features/user/userSlice";
import { selectTerminalMode } from "../features/user/userSlice";
import AmountView from "./common/AmountView";
import { Field, Form, Formik } from "formik";
import { makePyout } from "../api/rafflesApi";
import { object, string, number } from "yup";
import Popup from "./Popup";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CustomSelect } from "./common/CustomSelect";

enum WithdrawTypes {
  DubaiMall,
  EmiratesMall,
  Alancari,
}
export type PayOutFormValues = {
  name: string;
  doc: string;
  amount: number;
  payType: WithdrawTypes;
  phone: number | string;
};

let withdrawSchema = object({
  name: string().required("not set").min(3, "at least length of 3"),
  doc: string().required("not set").min(3, "at least length of 3"),
  amount: number().positive("Value must be positive."),
});
const phoneCountryOptions = [
  {
    label: "+971",
    value: "+971",
  },
];

const payTypeOptions = [
  // {
  //   label: "Shop(Dubai Mall)",
  //   value: WithdrawTypes.DubaiMall,
  // },
  // {
  //   label: "Shop(Emirate Mall)",
  //   value: WithdrawTypes.EmiratesMall,
  // },
  {
    label: "Al Ansari Exchange",
    value: WithdrawTypes.Alancari,
  },
];

const WithdrawAssets: React.FC<EmptyProps> = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const terminalMode = useAppSelector(selectTerminalMode);

  const dispatch = useAppDispatch();

  const payValue = WithdrawTypes.DubaiMall;

  // const payTypeOptions = useMemo(() => {
  //   if (!terminalMode) {
  //     return [
  //       {
  //         label: "Al Ansari Exchange",
  //         value: WithdrawTypes.Alancari,
  //       },
  //     ];
  //   }
  //   return [
  //     {
  //       label: "Shop(Dubai Mall)",
  //       value: WithdrawTypes.DubaiMall,
  //     },
  //     {
  //       label: "Shop(Emirate Mall)",
  //       value: WithdrawTypes.EmiratesMall,
  //     },
  //     {
  //       label: "Al Ansari Exchange",
  //       value: WithdrawTypes.Alancari,
  //     },
  //   ];
  // }, [terminalMode]);

  const [payType, setPayType] = useState(payValue);
  const initialValues = {
    name: "",
    payType: WithdrawTypes.DubaiMall,
    doc: "",
    amount: 50,
    phone: "",
  } as PayOutFormValues;

  const { t } = useTranslation();

  async function handleWithdrawSubmit(values: PayOutFormValues) {
    try {
      let data = await makePyout({
        ...values,
        phone: phoneCountryOptions[0].label + values.phone,
        payType,
      });
      let message = t("Your request has been received.");
      dispatch(getUserAsync());
      setSuccessMessage(message);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      }
    }
  }
  return (
    <>
      {errorMessage ? (
        <Popup
          headerText={"Transfer Failed"}
          onClose={() => {
            setErrorMessage("");
          }}
          contentText={errorMessage}
        ></Popup>
      ) : successMessage ? (
        <Popup
          onClose={() => {
            setSuccessMessage("");
          }}
          headerText={"Success"}
          contentText={successMessage}
        ></Popup>
      ) : null}
      <Formik
        validationSchema={withdrawSchema}
        initialValues={initialValues}
        onSubmit={handleWithdrawSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="profile-section-input-group">
              <div className="profile-input-container">
                <span className="profile-input-top-txt" id="app-title-1">
                  {t("Name")}
                </span>
                <ReactTooltip
                  variant="dark"
                  anchorSelect="#app-title-1"
                  place="top"
                  style={{ zIndex: 1 }}
                  content="Type in your full name as per your passport/ID."
                />
                <Field
                  name="name"
                  type="text"
                  placeholder="Enter your name"
                  className="profile-input"
                />
                {errors.name && touched.name ? (
                  <span style={{ color: "red" }}>{errors.name}</span>
                ) : null}
              </div>
              <div className="profile-input-container">
                <span className="profile-input-top-txt" id="app-title-2">
                  {t("Document No")}
                </span>
                <Field
                  type="text"
                  name="doc"
                  placeholder="Enter document number"
                  className="profile-input"
                />
                <ReactTooltip
                  variant="dark"
                  anchorSelect="#app-title-2"
                  place="top"
                  style={{ zIndex: 1 }}
                  content="Please type in your ID or Passport Number."
                />
                {errors.doc && touched.doc ? (
                  <span style={{ color: "red" }}>{errors.doc}</span>
                ) : null}
              </div>

              <div className="profile-input-container">
                <span className="profile-input-top-txt" id="app-title-4">
                  {t("Mobile Number")}
                </span>
                <ReactTooltip
                  variant="dark"
                  anchorSelect="#app-title-4"
                  place="top"
                  style={{ zIndex: 1 }}
                  content="Enter your phone number"
                />
                <div className="profile-input-with-dropdown">
                  <CustomSelect
                    options={phoneCountryOptions}
                    onValueChanged={() => {}}
                  />
                  <div
                    className="phone-dropdown-wrapper"
                    style={{ display: "none" }}
                  >
                    <div className="phone-dropdown-input-container active">
                      <input
                        type="number"
                        placeholder="+880"
                        className="profile-input"
                      />
                      <i className="icon-down-sr" />
                    </div>
                    <div className="phone-dropdown-container">
                      <div className="phone-dropdown-content">
                        <div className="phone-dropdown-row">
                          <span className="phone-dropdown-row-txt">+374</span>
                        </div>
                        <div className="phone-dropdown-row">
                          <span className="phone-dropdown-row-txt">+374</span>
                        </div>
                        <div className="phone-dropdown-row">
                          <span className="phone-dropdown-row-txt">+374</span>
                        </div>
                        <div className="phone-dropdown-row">
                          <span className="phone-dropdown-row-txt">+374</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Field
                    name="phone"
                    type="number"
                    className="profile-input"
                    placeholder=""
                  />
                </div>

                {/*<div className="nr-sign-up-input-group">*/}
                {/*    <div className="nr-sign-up-dropdown">*/}
                {/*        <div className="nr-dropdown-toggle">*/}
                {/*            <p className="nr-dropdown-txt">+880</p>*/}
                {/*            <div className="nr-dropdown-icon"><i className="icon-arrow-down"></i></div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <input type="number" className="nr-sign-up-num-input" />*/}
                {/*</div>*/}
              </div>
              <div className="profile-input-container">
                <span className="profile-input-top-txt" id="app-title-3">
                  {t("Mobile Number")}
                </span>
                <div className="profile-input-with-dropdown all">
                  <CustomSelect
                    options={payTypeOptions}
                    onValueChanged={(value) => setPayType(value)}
                  />
                </div>
              </div>
              <div className="profile-input-container">
                <span className="profile-input-top-txt" id="app-title-3">
                  {t("Amount")} (minimum <AmountView value={100} />)
                </span>
                <Field
                  name="amount"
                  type="number"
                  className="profile-input"
                  placeholder="0"
                />
                <ReactTooltip
                  variant="dark"
                  anchorSelect="#app-title-3"
                  place="top"
                  style={{ zIndex: 1 }}
                  content={t("Enter the amount you want to withdraw") || ""}
                />
                {errors.amount && touched.amount ? (
                  <span style={{ color: "red" }}>{errors.amount}</span>
                ) : null}
              </div>
              <div className="profile-main-button-wrapper">
                <button className="profile-main-button" type="submit">
                  <span className="profile-main-button-txt">
                    {t("Withdraw")}
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div
        style={{ padding: "2rem 0", fontSize: "1.4rem" }}
        dangerouslySetInnerHTML={{
          __html: t("transfer-withdraw-info-bottom-text") || "",
        }}
      ></div>
    </>
  );
};

export default WithdrawAssets;
