import React from "react";
import { RaffleProps } from "types";
import { RaffleCountDownTimer } from "./Home/UpcomingRaffles";
import AmountView from "./common/AmountView";
import { useTranslation } from "react-i18next";

const CurrentRaffleTopBanner: React.FC<RaffleProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="banner-block">
      <div className="banner-block-inner container">
        <div className="banner-body">
          <div className="join-raffle-countdown">
            <h4 className="join-raffle-countdown-title">
              #{data.id} {t(data.name)}
            </h4>
            <RaffleCountDownTimer
              type={"big"}
              startTime={data.startDate * 1000}
            />
            <p className="join-raffle-countdown-price">
              {data.prizeFund ? <AmountView value={data.prizeFund} /> : ''}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CurrentRaffleTopBanner;
