import React, { useMemo } from "react";
import { EmptyProps, RaffleInfo } from "types";
import { useTranslation } from "react-i18next";
import "../assets/styles/FairnessCheck.scss";
import { useAppSelector } from "../app/hooks";
import { selectRaffleInfo } from "../features/raffles/rafflesSlice";
import { NavLink, useParams } from "react-router-dom";
import classNames from "classnames";
import hmacSHA512 from "crypto-js/hmac-sha512";
import dayjs from "dayjs";

function getResult(raffleInfo: RaffleInfo) {
  let numbers: number[] = [];
  let n = 0;
  let nResults = [];
  while (numbers.length < raffleInfo.selectedCount) {
    let seed = hmacSHA512(
      raffleInfo.blockHash,
      raffleInfo.blockNumber + ":" + n.toString()
    );
    let hextodec = parseInt(seed.toString().substring(0, 10), 16).toString();
    let last4 = hextodec.substring(hextodec.length - 4, hextodec.length);
    let number = Math.abs(parseInt(last4)) % raffleInfo.numberCount;

    let val = {
      n,
      number: number + 1,
      seed: seed.toString(),
      taken: !numbers.includes(number),
    };
    if (!numbers.includes(number + 1)) {
      numbers.push(number + 1);
    }
    nResults.push(val);

    n++;
  }

  return {
    numbers,
    nResults,
  };
}

const FairnessCheck: React.FC<EmptyProps> = () => {
  const { t } = useTranslation();
  const { raffleId } = useParams();
  const raffleInfo = useAppSelector((state) =>
    selectRaffleInfo(state, raffleId || "")
  );

  const data = useMemo(() => {
    return getResult(raffleInfo);
  }, [raffleInfo]);

  return (
    <div className="container">
      <div className="fairness-check-wrapper">
        <span className="page-title">Fairness Check</span>
        <div className="fairness-page-top-min-section">
          <button className="fairness-back-button">
            <i className="icon-arrow-left" />
            <NavLink
              to={`/raffles/${raffleId}/info`}
              className="back-button-txt"
            >
              {t("Back")}
            </NavLink>
          </button>
          <span className="raffle-name-date-title">
            Raffle: {raffleInfo.name}
          </span>
          <div className="raffle-id-date-container">
            <span className="raffle-min-txt">
              Raffle ID:<span>{raffleInfo.id}</span>
            </span>
            <span className="raffle-min-txt">
              Raffle Date:
              <span>
                {dayjs(raffleInfo.startDate * 1000).format("hh:mm DD.MM.YYYY")}
              </span>
            </span>
          </div>
        </div>
        <div className="fairness-main-section">
          <span className="fairness-main-inner-title">
            Welcome to your lottery!
          </span>
          <div className="lottery-title-bot-texts-cont">
            <div className="block-txt-cont">
              <span className="raffle-min-txt">
                Block:<span>{raffleInfo.blockNumber}</span>
              </span>
              <a
                href={`https://sahara.ftnscan.com/block/${raffleInfo.blockHash}`}
                target="_blank"
                className="lottery-link-to"
              >
                <i className="icon-to"></i>
              </a>
            </div>
            <div className="block-txt-cont">
              <span className="raffle-min-txt">
                Block Hash:<span>{raffleInfo.blockHash}</span>
              </span>
            </div>
          </div>
          {/*<div className="lottery-with-input-container">*/}
          {/*    <span className="fairness-main-inner-title">Selection</span>*/}
          {/*    <span className="lottery-input-top-txt">*/}
          {/*        Description. Lorem Ipsum is simply dummy text of the printing*/}
          {/*    </span>*/}
          {/*    <div className="lottery-input">*/}
          {/*        <span className="lottery-input-txt">{data.formulaTxt}</span>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="lottery-with-input-container">
            <span className="fairness-main-inner-title">Formula</span>
            <span className="lottery-input-top-txt">
              Here are the function that is used to generate the numbers
            </span>
            <span className="lottery-input-top-txt">
              <code
                dangerouslySetInnerHTML={{
                  __html: `
     <pre>{
    <var>$numbers</var> = [];
                                
    $n = 0;
    while(count($numbers) < ${raffleInfo.selectedCount}){
    
        $seed = hash_hmac(
            'sha512',
            $bockHash,
            ${raffleInfo.blockNumber}.":".$n);
            
        $seed = hexdec(substr($seed,0,10));
        $number = abs(substr(strval($seed),-4))%${raffleInfo.numberCount};
        $number = $number + 1;
        
        if(!in_array($number, $numbers)){
            $numbers[] = $number;
        }
    
        $n++;
    }
    
    return $numbers;
}
</pre>
`,
                }}
              ></code>
            </span>
            {/*<div className="lottery-input">*/}
            {/*    <span className="lottery-input-txt">294524653427294524653427</span>*/}
            {/*</div>*/}
          </div>
          <div className="lottery-with-input-container">
            <span className="fairness-main-inner-title">
              Result generated on function execution
            </span>

            <div className="fairness-inputs-container">
              <div className="lottery-input">
                <span className="lottery-input-txt">
                  Result number | Generated Seed
                </span>
              </div>
              {data.numbers.map((result, idx) => (
                <React.Fragment key={result}>
                  <div className="lottery-input single">
                    <span className="lottery-input-txt win">{result}</span>
                  </div>
                  <div className="lottery-input">
                    <span className="lottery-input-txt">
                      {data.nResults[idx].seed}
                    </span>
                    <span className="icon-copy input-copy"></span>
                  </div>
                </React.Fragment>
              ))}

              {/*<div className="lottery-input">*/}
              {/*    {data.allNumbers.map((hx, idx)=>(<span key={idx} className="lottery-input-txt">{hx}</span>))}*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="lottery-with-input-container">
            <span className="fairness-main-inner-title">Result</span>
            <div className="lottery-input result">
              {data.numbers.map((hx, idx) => (
                <span key={idx} className="lottery-input-txt win">
                  {hx}
                </span>
              ))}

              {/*<span className="lottery-input-txt win">137</span>*/}
              {/*<span className="lottery-input-txt win">137</span>*/}
              {/*<span className="lottery-input-txt win">137</span>*/}
              {/*<span className="lottery-input-txt win">137</span>*/}
              {/*<span className="lottery-input-txt win">137</span>*/}
              {/*<span className="lottery-input-txt win">137</span>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FairnessCheck;
