import React, { SyntheticEvent, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { OtpData } from "types";
import { verifyOtpAsync } from "../../features/user/userSlice";
import { useAppDispatch } from "../../app/hooks";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { PayloadAction } from "@reduxjs/toolkit";
import { RejectedAction } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  AnyAsyncThunk,
  RejectedActionFromAsyncThunk,
} from "@reduxjs/toolkit/dist/matchers";
import { showToast } from "../ToastContainer";
import { resendOtp } from "../../api/rafflesApi";
import { useTranslation } from "react-i18next";

type Props = {
  otpUserId: number | null;
  otpVerificationType: number;
};
const VerifyOTPForm: React.FC<Props> = ({ otpUserId, otpVerificationType }) => {
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState("");
  const [resendEnabled, setResendEnabled] = useState(false);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(+new Date() + 5 * 60 * 1000);

  useEffect(() => {
    setStartDate(+new Date() + 1 * 60 * 1000);
  }, [resendEnabled]);

  async function handleOtpSubmit(values: OtpData) {
    let res = (await dispatch(
      verifyOtpAsync({
        otp,
        otpUserId,
      })
    )) as RejectedActionFromAsyncThunk<AnyAsyncThunk>;
    if (res.error) {
      setOtp("");
      showToast("error", res.error.message);
    }
  }

  async function handleResendOtp(e: SyntheticEvent) {
    e.stopPropagation();
    e.preventDefault();
    setResendEnabled(false);
    let res = await resendOtp({
      otpUserId,
    });
    showToast(
      res.code ? "error" : "success",
      res.message || "OTP successfully sent."
    );
  }

  return (
    <div className="nr-sign-up-otp">
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={handleOtpSubmit}
      >
        <Form>
          <h2 className="nr-sign-up-otp-title">{t("OTP Verification")}</h2>
          <h3 className="nr-sign-up-otp-subtitle">
            {t("Enter the code from the sms we sent to")}{" "}
            <strong>
              {otpVerificationType === 0
                ? t("your email address")
                : t("your phone number")}
            </strong>
          </h3>
          {!resendEnabled ? (
            <Countdown
              autoStart={true}
              date={startDate}
              renderer={({ minutes, seconds }) => (
                <span className="nr-sign-up-otp-timer">
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </span>
              )}
              onComplete={() => setResendEnabled(true)}
            />
          ) : null}

          <div className="nr-sign-up-otp-numbers">
            <div className="nr-sign-up-otp-number-numbers-holder">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                shouldAutoFocus={true}
                renderSeparator={""}
                inputStyle={"nr-sign-up-otp-numbers-input"}
                renderInput={(props) => (
                  <div className="nr-sign-up-otp-number">
                    <input {...props} type={"number"} />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="nr-sign-up-otp-notify">
            {resendEnabled ? (
              <>
                <p className="nr-sign-up-otp-notify-txt">
                  {t("dont-receive-otp-code")}
                </p>
                <button
                  className="nr-sign-up-otp-button"
                  onClick={handleResendOtp}
                >
                  Resend
                </button>
              </>
            ) : null}
          </div>
          <button type="submit" className="nr-sign-up-button">
            <span className="nr-sign-up-button-txt">{t("Log in")}</span>
          </button>
        </Form>
      </Formik>
    </div>
  );
  // return (
  //     <Formik
  //         initialValues={{
  //             otp: ''
  //         }} onSubmit={handleOtpSubmit}>
  //         <Form>
  //             <div className="nr-sign-up-form">
  //                 <label className="nr-sign-up-input-holder" htmlFor="">
  //                     <span className="nr-sign-up-input-label">{t('Otp Code')}</span>
  //                     <Field name="otp" type="text" className="nr-sign-up-input"/>
  //                 </label>
  //             </div>
  //             <button type="submit" className="nr-sign-up-button">
  //                 <span className="nr-sign-up-button-txt">{t('Log in')}</span>
  //             </button>
  //         </Form>
  //     </Formik>
  // )
};

export default VerifyOTPForm;
