import {useState} from "react";

export default function usePagination(itemsPerPageInitial:number = 5){
    const [page, setPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageInitial)

    function changeToNextPage(){
        setPage(page+1)
    }
    function changeToPrevPage(){
        if(page > 0)
            setPage(page-1)
    }
    function changeItemsPerPage(value:number){
        setItemsPerPage(value)
    }
    return {
        page,
        itemsPerPage,
        changeToNextPage,
        changeToPrevPage,
        changeItemsPerPage
    }

}