export const enum Event {
  Show,
  Clear,
}

export type ToastContent = {
  content: string;
  level: string;
};

type OnClearCallback = (toastContent: ToastContent) => void;

type Callback = OnClearCallback;

type TimeoutId = ReturnType<typeof setTimeout>;

export interface EventManager {
  list: Map<string, Callback[]>;
  emitQueue: Map<string, TimeoutId[]>;
  on(event: string, callback: Callback): EventManager;

  off(event: string, callback?: Callback): EventManager;
  emit(event: string, content: ToastContent): void;
}

export const eventManager: EventManager = {
  list: new Map(),
  emitQueue: new Map(),

  on(event: string, callback: Callback) {
    this.list.has(event) || this.list.set(event, []);
    this.list.get(event)!.push(callback);
    return this;
  },

  off(event: string, callback) {
    if (callback) {
      const cb = this.list.get(event)!.filter((cb) => cb !== callback);
      this.list.set(event, cb);
      return this;
    }
    this.list.delete(event);
    return this;
  },

  /**
   * Enqueue the event at the end of the call stack
   * Doing so let the user call toast as follow:
   * toast('1')
   * toast('2')
   * toast('3')
   * Without setTimemout the code above will not work
   */
  emit(event: string, ...args: any[]) {
    this.list.has(event) &&
      this.list.get(event)!.forEach((callback: Callback) => {
        const timer: TimeoutId = setTimeout(() => {
          // @ts-ignore
          callback(...args);
        }, 0);

        this.emitQueue.has(event) || this.emitQueue.set(event, []);
        this.emitQueue.get(event)!.push(timer);
      });
  },
};
