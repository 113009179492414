import { CountdownTimeDelta } from "react-countdown/dist/utils";
import { stringOrNumber } from "./Helpful";
import { BasketTicket } from "./Basket";
import { NftCard } from "./Nfts";

export interface AddRaffleData {
  raffleId: number;
  promoCode: string;
  tickets: {
    numbers: number[];
    nftId: number;
  }[];
  paySource: number;
}

export interface RafflePayoutScheme {
  [key: string]: number;
}

export enum RaffleStatus {
  UPCOMING = 1,
  STARTED = 2,
  ENDED = 3,
}

export interface Raffle {
  id: number;
  name: string;
  blockNumber: number;
  startDate: number;
  prizeFund: number;
  joined: boolean;
  guaranteedPrize: number;
  status: RaffleStatus;
  result: number[];
  payoutScheme: RafflePayoutScheme;
  ticketPrice: number;
  tickets: RaffleTicket[];
  currency: string;
  blockHash: string;
  selectedCount: number;
  numberCount: number;
  type: number;
}

interface RaffleTicket {
  count: number;
  status: "none" | "pending" | "win" | "lose";
  winAmount: number;
}

export interface RafflesState {
  raffles: Raffle[];
  showUpcomingRaffleInfo: boolean;
  totalInfo: {
    id: number;
    tickets: number;
    totalWins: number;
  };
  status: "idle" | "loading" | "failed" | "success";
  raffleInfo: {
    [raffleId: stringOrNumber]: RaffleInfo;
  };
}

export interface RaffleBasket {
  tickets: BasketTicket[];
  nfts: NftCard[];
  raffleNfts: NftCard[];
  raffleId: number;
  status: string;
  pendingTrxId?: number;
  stripeOrderValue?: string;

  errorMessage?: string;
}

export type RaffleProps = {
  data: Raffle;
  type?: string;
  onComplete?: (
    timeDelta: CountdownTimeDelta,
    completedOnStart: boolean
  ) => void;
};

export interface RaffleButtonProps {
  data: Raffle;
  className?: string;
}

export interface RaffleInfoData {
  raffleId: stringOrNumber;
  page: 0;
}

export interface RaffleInfo extends Raffle {
  id: number;
  myTickets: RaffleResultTicket[];
  winners: RaffleResultTicket[];
}

export interface RaffleResultTicket {
  userId: number;
  ticketId: number;
  numbers: number[];
  status: number;
  win: number;
}

export interface RaffleTicketRowProps {
  raffle?: Raffle;
  nft: NftCard;
  number: number;
  ticket: BasketTicket;
}
