import React, {
  ElementRef,
  Ref,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getUserAsync,
  loginAsync,
  selectOtpUserId,
  verifyOtpAsync,
} from "../features/user/userSlice";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { OtpData, UserLoginData } from "types";
import VerifyOTPForm from "../components/Authorisation/VerifyOTPForm";
import Loader from "../assets/images/sign-up-loader.png";
import classNames from "classnames";
import ScrollToTop from "../ScrollToTop";
import { useTranslation } from "react-i18next";
import { getUser } from "../api/rafflesApi";

type Props = {};

const Login: React.FC<Props> = () => {
  const { pathname, state } = useLocation();
  const isLogin = pathname === "/login";
  const dispatch = useAppDispatch();
  const otpUserId = useAppSelector(selectOtpUserId);
  const [otpVerificationType, setOtpVerificationType] = useState(0);
  const scrollRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  async function handleButtonClick(values: UserLoginData) {
    await dispatch(loginAsync(values));
    dispatch(getUserAsync());
  }

  useEffect(() => {
    scrollRef.current &&
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [scrollRef.current]);

  return (
    <div className="nr-sign-up-holder">
      <div className="nr-sign-up">
        <div className="nr-sign-up-bg-holder">
          <div className="nr-sign-up-bg" />
        </div>
        <div className="nr-sign-up-info">
          <div className="nr-sign-up-info-inside">
            <h2 className="nr-sign-up-title">
              {isLogin ? t("Sign In") : t("Sign Up")}
            </h2>
            <h3 ref={scrollRef} className="nr-sign-up-subtitle">
              {t("Complete Your participation")}
            </h3>
            <div className="nr-sign-up-tabs" style={{ display: "none" }}>
              <div className="nr-sign-up-tabs-inside">
                <div
                  className={classNames("nr-sign-up-tab ", {
                    active: otpVerificationType === 0,
                  })}
                  onClick={() => setOtpVerificationType(0)}
                >
                  <p className="nr-sign-up-tab-txt">{t("With email")}</p>
                </div>
                <div
                  className={classNames("nr-sign-up-tab ", {
                    active: otpVerificationType === 1,
                  })}
                  onClick={() => setOtpVerificationType(0)}
                >
                  <p className="nr-sign-up-tab-txt">
                    {t("With mobile number")}
                  </p>
                </div>
                <span className="nr-sign-up-tab-switcher" />
              </div>
            </div>
            {/* Mobile verification block */}

            {/* OTP verification block */}

            {otpUserId ? (
              <VerifyOTPForm
                otpUserId={otpUserId}
                otpVerificationType={otpVerificationType}
              />
            ) : otpVerificationType === 1 ? (
              <div className="nr-sign-up-mobile-number">
                <h2 className="nr-sign-up-mob-num-txt">Mobile Number</h2>
                <div className="nr-sign-up-input-group">
                  <div className="nr-sign-up-dropdown">
                    <div className="nr-dropdown-toggle">
                      <p className="nr-dropdown-txt">+880</p>
                      <div className="nr-dropdown-icon">
                        <i className="icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <input type="number" className="nr-sign-up-num-input" />
                </div>
                <button type="submit" className="nr-sign-up-button">
                  <span className="nr-sign-up-button-txt">{t("Log In")}</span>
                </button>
              </div>
            ) : (
              <Formik
                onSbmit={handleButtonClick}
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                }}
                onSubmit={handleButtonClick}
              >
                <Form>
                  <div className="nr-sign-up-form">
                    <label className="nr-sign-up-input-holder" htmlFor="">
                      <span className="nr-sign-up-input-label">
                        {t("Name")}
                      </span>
                      <Field
                        name="name"
                        type="text"
                        className="nr-sign-up-input"
                      />
                    </label>
                    <label className="nr-sign-up-input-holder" htmlFor="">
                      <span className="nr-sign-up-input-label">
                        {t("Email address")}
                      </span>
                      <Field
                        name="email"
                        type="email"
                        className="nr-sign-up-input"
                      />
                    </label>
                    <Field
                      name="phone"
                      type="hidden"
                      className="nr-sign-up-input"
                    />

                    <div
                      className="nr-sign-up-checkbox-holder"
                      style={{ display: "none" }}
                    >
                      <div className="nr-sign-up-checkbox">
                        <div className="nr-sign-up-checkbox-wrapper">
                          <input
                            className="nr-sign-up-checkbox-input"
                            id="nr-sign-up-checkbox"
                            type="checkbox"
                          />
                          <span className="nr-sign-up-checkbox-checkmark" />
                        </div>

                        <label
                          className="nr-sign-up-checkbox-label"
                          htmlFor="nr-sign-up-checkbox"
                        >
                          Remember me
                        </label>
                      </div>
                      {/*{isLogin ? <NavLink to={'/forgot-password'} className="nr-sign-up-checkbox-link">Forgot*/}
                      {/*    password?</NavLink> : null}*/}
                    </div>
                    {/* Add class // disabled // for nr-sign-up-button */}

                    <button type="submit" className="nr-sign-up-button">
                      <span className="nr-sign-up-button-txt">
                        {t("Log In")}
                      </span>
                    </button>
                    {/*<p className="nr-sign-up-question">Already have an account?*/}
                    {/*    <a className="nr-sign-up-link" href="#">Sign in.</a>*/}
                    {/*</p>*/}
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
