import React, {useEffect} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {logoutAsync, selectTerminalMode, selectUser} from "../../features/user/userSlice";
import {selectBasket} from "../../features/basket/basketSlice";
import LanguageSwitcher from "../LanguageSwitcher";
import ShutDownTerminalButton from "../ShutDownTerminalButton";
import {useTranslation} from "react-i18next";
import AmountView from "../common/AmountView";

type Props = {
    closeSideBar:()=>void
}
const UserHeader: React.FC<Props> = ({closeSideBar}) => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const terminalMode = useAppSelector(selectTerminalMode)
    const {t} = useTranslation()
    if (user?.id)
        return (
            <>
                <LanguageSwitcher/>

                <NavLink to={'/user-page'} className='cart-block'>

                    <div className="nr-raffle-participants-icon cart-circle">{user.firstName[0]}</div>
                    <div className='cart-block-content'>
                        <h6 className='cart-block-title'>{user.firstName}</h6>
                        <p className='cart-block-value'><AmountView value={user?.balance}/></p>
                    </div>
                </NavLink>
                {!terminalMode ? <button className="header-signup hide-on-mobile" onClick={() => {dispatch(logoutAsync())}}>{t('Log Out')}</button> :null}
            </>
        )
    return (
        <div className="header-signup-container">
            <LanguageSwitcher className="hide-on-mobile"/>

            <Link to='/login' className='header-login' onClick={closeSideBar}>{t('Sign In')}</Link>
            <Link to='/signup' className='header-signup ' onClick={closeSideBar}>{t('Sign Up')}</Link>
            <ShutDownTerminalButton />
        </div>
    )
};


export default UserHeader
