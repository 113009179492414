import { EmptyProps } from "types";
import React, { ReducerAction, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer";
import { useOutsideClick } from "../hooks/useOnClickOutside";
import { set } from "lodash";
import classNames from "classnames";

interface PaginationProps {
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  onItemsPerPageChange: (page: number) => void;
  itemsPerPage: number;

  page: number;
  nextButtonEnabled: boolean;
}
const ITEMS_LIST_VALUES = [5, 10, 15, 20];
const Pagination: React.FC<PaginationProps> = ({
  onNextButtonClick,
  onPrevButtonClick,
  page,
  onItemsPerPageChange,
  itemsPerPage,
  nextButtonEnabled,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const ref = useOutsideClick(() => {
    setOpened(false);
  });
  function handleItemsPerPageChange(value: number) {
    onItemsPerPageChange(value);
  }

  return (
    <div className="pagination-wrapper">
      <div className="pagination-txt-block">
        <p className="pagination-txt-page">{t("Items per page")}</p>
        <div
          className={classNames("pagination-arrow-block", { open: opened })}
          onClick={() => setOpened(!opened)}
          ref={ref}
        >
          {" "}
          {/*add/remove class open*/}
          <span className="pagination-arrow-count">{itemsPerPage}</span>
          <i className="icon-arrow-top" />
          <div className="pagination-count-open-block">
            {ITEMS_LIST_VALUES.map((i) => (
              <span onClick={() => handleItemsPerPageChange(i)} key={i}>
                {i}
              </span>
            ))}
          </div>
        </div>
      </div>
      <p className="pagination-count-block">{page + 1}</p>
      <div className="pagination-icon-block">
        <i
          className={classNames("icon-arrow-left", { disabled: page <= 0 })}
          onClick={() => onPrevButtonClick()}
        />
        <i
          className={classNames("icon-arrow-right", {
            disabled: nextButtonEnabled,
          })}
          onClick={() => onNextButtonClick()}
        />
      </div>
    </div>
  );
};
export default Pagination;
