import React from "react";
import UpcomingRaffles from "../components/Home/UpcomingRaffles";
import LatestDrawResults from "../components/Home/LatestDrawResults";
import CurrentRaffleTopBanner from "../components/CurrentRaffleTopBanner";
import StripeForm from "../components/StripeForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectTotalTickets,
  selectTotalWins,
  selectUpcomingRaffles,
} from "../features/raffles/rafflesSlice";
import AmountView, { formatValue } from "../components/common/AmountView";
import { useAppSelector } from "../app/hooks";
import { selectUserCurrencyTemplate } from "../features/user/userSlice";
import numeral from "numeral";

type Props = {};

const Home: React.FC<Props> = () => {
  const { t } = useTranslation();
  const raffles = useSelector(selectUpcomingRaffles);
  const totalWins = useSelector(selectTotalWins);
  const template = useAppSelector(selectUserCurrencyTemplate);

  console.log("raffles[0]?.ticketPrice", raffles[0]?.ticketPrice);

  return (
    <div className="home-page">
      {/*get the chance row START*/}
      <UpcomingRaffles />
      {/*get the chance row END*/}
      {/*<CurrentRaffleTopBanner/>*/}

      {/* Latest draw results START */}
      <LatestDrawResults />
      {/* Latest draw results END */}
      {/*hash raffles START*/}
      <div className="hash-raffle-content container">
        {/*<h1 className="hash-raffle-header">{t('Hash Raffles Await You')}</h1>*/}
        <div className="hash-raffle-top">
          <div className="hash-raffle-left">
            <p className="hash-raffle-question">{t("How to Participate")}</p>
            <p
              className="hash-raffle-question-desc"
              dangerouslySetInnerHTML={{
                __html: t("how-to-play-text", {
                  value: formatValue(raffles[0]?.ticketPrice || 5, template),
                }) as string,
              }}
            ></p>
            {/*<p className="hash-raffle-question-message ">{t('Time to be and feel in the spotlight again!')}</p>*/}
            {/*<a className="hash-raffle-question-message message-space">{t('Buy now!')}</a>*/}
          </div>
        </div>
        <div className="hash-raffle-bottom">
          <div className="hash-raffle-left">
            <p className="hash-raffle-bottom-info hash-raffle-bottom-name">
              {t("Total winners of the day")}
            </p>
            <p className="hash-raffle-bottom-info hash-raffle-bottom-desc">
              {<AmountView value={totalWins} />}
            </p>
          </div>
        </div>
      </div>
      {/*hash raffles END*/}
    </div>
  );
};

export default Home;
