import {Link, NavLink} from "react-router-dom";

import logo from './../assets/images/logo.svg';
import logoMob from './../assets/images/logo_mob.svg';
import React, {useState} from "react";
import UserHeader from "./Authorisation/UserHeader";
import LanguageSwitcher from "./LanguageSwitcher";
import ShutDownTerminalButton from "./ShutDownTerminalButton";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {logoutAsync, selectUser} from "../features/user/userSlice";
import {useTranslation} from "react-i18next";

type Props = {};

const Header: React.FC<Props> = () => {
    const [isNavOpened, setIsNavOpened] = useState(false);
    const {t} = useTranslation()
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch()
    return (
        <div className="header">
            <div className='header-inner container'>
                <div className='header-nav-col'>
                    <Link to='/' className='logo'>
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={logo} />
                            <img src={logoMob} alt="Flowers" />
                        </picture>
                    </Link>
                    <div className={`main-panel ${isNavOpened ? 'active' : ''}`}>
                        <div className='main-menu'>
                            <NavLink to='/' onClick={()=>setIsNavOpened(false)}>Home</NavLink>
                            {user?.id ? <>
                                <NavLink to='/user-page/Profile' onClick={()=>setIsNavOpened(false)}>Profile</NavLink>
                                <NavLink to='/user-page/ticket_history' onClick={()=>setIsNavOpened(false)}>My Tickets</NavLink>
                                <NavLink to='/user-page/ballance_summary' onClick={()=>setIsNavOpened(false)}>Balance Summary</NavLink>
                                <NavLink to='/user-page/payout' onClick={()=>setIsNavOpened(false)}>Payout</NavLink>
                                <NavLink to='/user-page/deposit' onClick={()=>setIsNavOpened(false)}>Deposit</NavLink>
                                {/*<NavLink to='/user-page/cart' onClick={()=>setIsNavOpened(false)}>Cart</NavLink>*/}
                            </>
                                : null
                            }
                            <NavLink to='/terms' onClick={()=>setIsNavOpened(false)}>{t('Terms And Conditions')}</NavLink>
                            <div className='header-action-col'>
                                <LanguageSwitcher/>
                            </div>
                            <div className="header-log-sign-btn-cont">
                                {!user?.id ? <>
                                        {/*Sos jan sranq en*/}
                                        <Link to='/login' className='header-login' onClick={()=>setIsNavOpened(false)}>Sign In</Link>
                                        <Link to='/signup' className='header-signup' onClick={()=>setIsNavOpened(false)}>Sign Up</Link>
                                    </> :

                                    <button className="header-signup" onClick={() => {dispatch(logoutAsync())}}>log out</button>}
                            </div>
                        </div>

                    </div>
                    <UserHeader closeSideBar={()=>setIsNavOpened(false)}/>
                    <ShutDownTerminalButton/>

                    <button type='button' onClick={() => setIsNavOpened((current) => !current)} className='burger-button icon-burger' />
                </div>
            </div>
        </div>
    );
};

export default Header;
