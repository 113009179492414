import React from "react";
import { EmptyProps } from "types";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logoutAsync, selectTerminalMode } from "../features/user/userSlice";
import externalApi from "../api/externalApi";
import { useTranslation } from "react-i18next";

const ShutDownTerminalButton: React.FC<EmptyProps> = () => {
  const terminalMode = useAppSelector(selectTerminalMode);
  const dispatch = useAppDispatch();
  async function handleShutDown() {
    await dispatch(logoutAsync());
    externalApi.showTapPage(true);
  }
  const { t } = useTranslation();
  if (!terminalMode) return null;
  return (
    <button className="header-terminal" onClick={handleShutDown}>
      {t("End Session")}
    </button>
  );
};

export default ShutDownTerminalButton;
