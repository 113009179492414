import React, { useCallback, useEffect, useState } from "react";
import { EmptyProps } from "types";
import { useOutsideClick } from "../hooks/useOnClickOutside";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { direction } from "direction";
import { useAppSelector } from "../app/hooks";
import { selectTerminalMode } from "../features/user/userSlice";

const options = ["en", "ar", "ru", "hi", "ur", "zh"]; 

if (process.env.NODE_ENV === "development") options.push("hy");

const LanguageSwitcher: React.FC<{ className?: string }> = ({ className }) => {
  const [opened, setOpened] = useState(false);
  const terminalMode = useAppSelector(selectTerminalMode);
  const ref = useOutsideClick(() => {
    setOpened(false);
  });
  const { t, i18n } = useTranslation();
  async function changeLng(lng: string) {
    await i18n.changeLanguage(lng);
    setOpened(false);
  }

  console.log("terminalMode", terminalMode);

  useEffect(() => {
    // document.documentElement.setAttribute('dir', direction(t('Log In')))
  }, [i18n.language, t]);

  if (terminalMode) return null;
  return (
    <div
      className={classNames("lang-block", className, { active: opened })}
      ref={ref}
      onClick={() => setOpened(!opened)}
    >
      {" "}
      {/*add/remove active class*/}
      <p className="lang-selected">{i18n.language}</p>
      <div className="lang-list">
        {options.map((op) => (
          <span
            onClick={() => changeLng(op)}
            key={op}
            className={classNames("lang-list-el", {
              active: op === i18n.language,
            })}
          >
            {op}
          </span>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
