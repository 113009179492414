import React from "react";
import { EmptyProps, UserRedirect } from "types";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const UnAuthorisedRoutes: React.FC<UserRedirect> = ({
  user,
  redirectPath = "/landing",
}) => {
  const location = useLocation();

  if (user?.id) {
    return <Navigate to={location.state?.redirectTo || redirectPath} replace />;
  }

  return <Outlet />;
};

export default UnAuthorisedRoutes;
