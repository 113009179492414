import {PropsWithChildren} from "react";

type Props = {
    headerText:string
    contentText:string,
    onClose?:()=>void
};

const Popup: React.FC<Props & PropsWithChildren> = ({
    contentText,headerText,children,onClose
                                }) => {
    return <div className="popup-holder">
        <div className="popup-content confetti">
            {/*add or remove class "confetti"*/}
            <div className="popup-head">
                <h3 className="popup-title">{headerText}</h3>
                {onClose ? <button className="popup-close" onClick={onClose}>
                    <i className="icon-close-n"/>
                </button> : null}
            </div>
            <p className="popup-text" dangerouslySetInnerHTML={{__html:contentText}}>
                {/*Your purchase has been successfully processed. <br/>*/}
                {/*To ensure that you do not miss the <b>04.03.2023</b> raffle, we kindly <br/>*/}
                {/*suggest setting a reminder.*/}
            </p>
            <div className="popup-buttons">
                {children}
                {/*<button className="popup-button outline">delete</button>*/}
                {/*<button className="popup-button fill">cancel</button>*/}
            </div>
        </div>
    </div>;
};

export default Popup;
