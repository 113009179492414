import {useAppSelector} from "../../app/hooks";
import {selectUser} from "../../features/user/userSlice";
import {Navigate} from "react-router-dom";
import {updateUserProfile} from "../../api/rafflesApi";
import AmountView from "../../components/common/AmountView";
import WithdrawAssets from "../../components/WithdrawAssets";
import { Tooltip } from 'react-tooltip'
import DepositForm from "../../components/DepositForm";

type Props = {};

const Deposit: React.FC<Props> = () => {
    const user = useAppSelector(selectUser)


    async function handleUpdateProfile(){
        if(user)
        await updateUserProfile({
            firstName:"",
            lastName:"",
            phone:0
        })
    }


    if(!user){
        return <Navigate to={'/home'}/>
    }
    return (
        <div className="my-profile-wrapper">
            <div className="profile-main-title-row">
                <p className="b-s-header-txt">Deposit</p>
                <div className="bal-cur-items-container">
                    <div className="cur-bal-item">
                        <span className="cur-bal-item-txt">Balance: <AmountView value={user.balance}/></span>
                    </div>
                    {/*<div className="cur-bal-item">*/}
                    {/*    <span className="cur-bal-item-txt">Currency: AED</span>*/}
                    {/*    <i className="icon-arrow-top" />*/}
                    {/*</div>*/}
                </div>
            </div>
            <DepositForm/>
            <div className="profile-info-bottom-section" style={{display:"none"}}>
                <div className="profile-main-title-row">
                    <p className="b-s-header-txt">Changes Password</p>
                </div>
                <div className="profile-section-input-group">
                    <div className="profile-input-container">
                        <span className="profile-input-top-txt">Old Password</span>
                        <input type="text" placeholder="Old Password" className="profile-input"/>
                    </div>
                    <div className="profile-input-container">
                        <span className="profile-input-top-txt">New Password</span>
                        <input type="text" placeholder="New Password" className="profile-input"/>
                    </div>
                    <div className="profile-input-container">
                        <span className="profile-input-top-txt">Confirm Password</span>
                        <input type="text" placeholder="Confirm Password" className="profile-input"/>
                    </div>
                    <div className="profile-main-button-wrapper">
                        <button className="profile-main-button">
                            <span className="profile-main-button-txt">Change</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;
