import React, {PropsWithChildren, useState} from "react";
import {useOutsideClick} from "../hooks/useOnClickOutside";
import classNames from "classnames";
import WithdrawAssets from "./WithdrawAssets";
import {useTranslation} from "react-i18next";

export type DropDownComponentProps = {
    label:React.ComponentElement<any, any>,
    buttonText:string
} & PropsWithChildren
const DropDownWrapper:React.FC<DropDownComponentProps> = ({children, label, buttonText})=>{
    const [opened, setOpened] = useState(false)
    const {t} = useTranslation()
    return (
        <div className="b-s-bal-row-columns-content">
            <div className="b-s-bal-row-columns">
                {label}
                <div className="b-s-bal-row-columns-button-cont">
                    <button className="b-s-bal-row-button pink-button"  onClick={()=>setOpened(!opened)}>
                        {t(buttonText)}
                    </button>
                </div>
            </div>

            <div className={classNames("b-s-dropdown-content-container",{active:opened})}>
                <div className="b-s-dropdown-container">
                    {children}
                </div>
            </div>



        </div>
    )
}

export default DropDownWrapper