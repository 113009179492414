import {NavLink} from "react-router-dom";
import SocialIcons from "./Footer/SocialIcons";
import {useTranslation} from "react-i18next";

type Props = {};

const Footer: React.FC<Props> = () => {
    const {t} = useTranslation()
    return <div className="footer">
        <div className="footer-container">
            <div className="ft-inner-container container">
                <div className="logo-ft-row">
                    <div className="ft-logo-container">
                        <div className="ft-logo"/>
                    </div>
                </div>
                <div className="ft-nav-row">
                    <NavLink to="/terms" className="ft-nav-text">
                        {t('Terms And Conditions')}
                    </NavLink>
                    {/*<a className="ft-nav-text" href="#">*/}
                    {/*    Newsroom*/}
                    {/*</a>*/}
                    {/*<a className="ft-nav-text" href="#">*/}
                    {/*    Documentation*/}
                    {/*</a>*/}
                    {/*<a className="ft-nav-text" href="#">*/}
                    {/*    FTN Usability*/}
                    {/*</a>*/}
                    {/*<a className="ft-nav-text" href="#">*/}
                    {/*    About us*/}
                    {/*</a>*/}
                </div>

                <SocialIcons/>
                <div className="ft-bottom-info">
                    <p className="info-bottom-ft-text" dangerouslySetInnerHTML={{__html:t('copyright-text',{year:2023}) || ''}}></p>
                </div>
            </div>
        </div>

    </div>;
};

export default Footer;
