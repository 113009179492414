import { EmptyProps } from "../shared/types";
import React, { useEffect, useState } from "react";
import { eventManager, ToastContent } from "types";
import classNames from "classnames";

export const showToast = (level: string, content: string) => {
  eventManager.emit("show", {
    level,
    content,
  });
};

let minF = 0;
const ToastContainer: React.FC<EmptyProps> = () => {
  const [toasts, setToasts] = useState<(ToastContent & { id: number })[]>([]);
  useEffect(() => {
    eventManager.on("show", (toastContent) => {
      setToasts((toasts) => [
        ...toasts,
        {
          ...toastContent,
          id: ++minF,
        },
      ]);
    });
    return () => {
      eventManager.off("show");
    };
  }, []);

  function handleCloseToast(id: number) {
    setToasts((toasts) => toasts.filter((t) => t.id !== id));
  }

  return (
    <div className="toaster-wrp">
      {toasts.map((t) => (
        <Toast
          key={t.id}
          id={t.id}
          level={t.level}
          content={t.content}
          onClose={handleCloseToast}
        />
      ))}
    </div>
  );
};

const classLevelMap = {
  success: "icon-claimed-fill",
  error: "icon-close-fill",
  warning: "icon-warning-fill",
} as { [key: string]: string };

const classLevelMap1 = {
  success: "check",
  error: "close",
  warning: "warning",
} as { [key: string]: string };
const Toast: React.FC<{
  id: number;
  level: string;
  content: string;
  onClose: (id: number) => void;
}> = ({ level, id, onClose, content }) => {
  useEffect(() => {
    let t = setTimeout(() => {
      onClose(id);
    }, 2000);
    return () => {
      clearInterval(t);
    };
  }, []);
  return (
    <div className="toaster-block">
      <div
        className={classNames("toaster-block-icon-left", classLevelMap1[level])}
      >
        <i className={classLevelMap[level]} />
      </div>
      <p className="toaster-block-txt">{content}</p>
      <div className="toaster-block-icon-close" onClick={() => onClose(id)}>
        <i className="icon-close-n" />
      </div>
    </div>
  );
};

export default ToastContainer;
