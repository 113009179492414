import {EmptyProps} from "../../shared/types";
import OnlyForWeb from "../common/OnlyForWeb";
import React from "react";

// Instagrami hamar sa lini: https://instagram.com/first.ticket?igshid=OGQ5ZDc2ODk2ZA==
//     Telegrami hamar sa: https://t.me/first_ticket
//     Facebooki hamarel sa: https://www.facebook.com/profile.php?id=100090198596806
const SocialIcons:React.FC<EmptyProps> = ()=>{
    return (
        <OnlyForWeb>
            <div className="ft-social-row">
                <a target="_blank" className="icon-around-facebook social-icon" href="https://www.facebook.com/profile.php?id=100090198596806"/>
                {/*<a className="icon-twitter social-icon" href="#"/>*/}
                {/*<a className="icon-linkedin social-icon" href="#"/>*/}
                <a target="_blank" className="icon-instagram-bg social-icon" href="https://instagram.com/first.ticket?igshid=OGQ5ZDc2ODk2ZA=="/>
                {/*<a className="icon-youtube social-icon" href="#"/>*/}
                <a target="_blank" className="icon-telegram social-icon" href="https://t.me/first_ticket"/>
                {/*<a className="icon-hoory social-icon" href="#"/>*/}
            </div>
        </OnlyForWeb>
    )
}

export default SocialIcons