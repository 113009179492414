import { EmptyProps } from "types";
import { RaffleCountDownTimer } from "./Home/UpcomingRaffles";
import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  hideUpcomingRaffleInfo,
  selectUpcomingRaffles,
} from "../features/raffles/rafflesSlice";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpcomingRaffleInfo: React.FC<EmptyProps> = () => {
  const raffles = useAppSelector(selectUpcomingRaffles);
  const showUpcomingRaffleInfo = useAppSelector(
    (state) => state.root.showUpcomingRaffleInfo
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  function handleCloseUpcomingRaffleInfo() {
    dispatch(hideUpcomingRaffleInfo());
  }

  if (!raffles.length) return null;
  if (!showUpcomingRaffleInfo) return null;
  return (
    <div className="top-min-banner">
      <div className="top-min-with-ellipse">
        <span className="top-banner-min-ellipse">1</span>
        <span className="top-min-banner-txt">
          You have one upcoming raffle scheduled in
        </span>
      </div>
      <div className="top-banner-hours-container">
        {raffles[0] ? (
          <RaffleCountDownTimer startTime={raffles[0].startDate * 1000} />
        ) : null}
      </div>
      <div className="top-min-with-link">
        <span className="top-min-banner-txt">
          {t("Thank you for your participation and good luck in the raffle")}
        </span>
        <NavLink to={`/raffles/${raffles[0].id}/tickets`}>
          {t("Go To Raffle")}
        </NavLink>
      </div>
      <i
        className="icon-close-n min-banner-close"
        onClick={handleCloseUpcomingRaffleInfo}
      />
    </div>
  );
};

export default UpcomingRaffleInfo;
