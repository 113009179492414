import {EmptyProps} from "../shared/types";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getUserAsync, selectUser} from "../features/user/userSlice";
import AmountView from "./common/AmountView";
import {Field, Form, Formik} from "formik";
import {makeDeposit} from "../api/rafflesApi";
import {object, number} from "yup"
import Popup from "./Popup";
import {useTranslation} from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import StripeForm from "./StripeForm";
import Loader from "./Loader";




let withdrawSchema = object({
    amount: number()
        .positive('Value must be positive.')


});


export type DepositFormValues = {
    amount:number

}

const DepositForm:React.FC<EmptyProps> = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useAppDispatch()
    const [successMessage, setSuccessMessage] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [loading, steLoading] = useState(false)
    const initialValues = {
        amount:50
    } as DepositFormValues

    const {t} = useTranslation()

    function handleStripePaymentSuccess(){
        let message = t("Your request has been received.");
        dispatch(getUserAsync())
        setSuccessMessage(message)
    }
    async function handleWithdrawSubmit(values:DepositFormValues){
        try {
            steLoading(true)
            let data = await makeDeposit(values)
            console.log(data)
            setClientSecret(data.payment.clientSecret)
            // let message = t("Your request has been received.");
            // dispatch(getUserAsync())
            // setSuccessMessage(message)
        }
        catch (e: unknown){
            if(e instanceof Error) {
                setErrorMessage(e.message)
            }
        }
        finally {
            steLoading(false)

        }
    }
    return(


            <>

                {
                    errorMessage ? <Popup headerText={'Transfer Failed'}
                                          onClose={()=>{setErrorMessage('')}}
                                          contentText={errorMessage}>
                    </Popup> : successMessage ? <Popup
                        onClose={()=>{setSuccessMessage('')}}
                        headerText={'Success'} contentText={successMessage}></Popup> : null
                }
                {clientSecret
                    ? <div className="profile-section-input-group">
                    <StripeForm
                        onSuccess={handleStripePaymentSuccess}
                        clientSecret={clientSecret}
                        raffleId={1}
                        loadStateChanged={()=>{}}
                    /> </div>:
                <Formik
                    validationSchema={withdrawSchema}

                    initialValues={initialValues} onSubmit={handleWithdrawSubmit}>
                    {({ errors, touched }) => (

                        <Form>
                            <div className="profile-section-input-group">

                                <div className="profile-input-container" >
                                    <span className="profile-input-top-txt" id="app-title-5">{t('Amount')}</span>
                                    <Field name="amount" type="number" className="profile-input" placeholder="0"/>
                                    <ReactTooltip
                                        variant="dark"
                                        anchorSelect="#app-title-5"
                                        place="top"
                                        style={{zIndex:1}}
                                        content={t("Enter the amount you want to deposit") || ''}
                                    />
                                    {errors.amount && touched.amount ? (
                                        <span style={{color:'red'}}>{errors.amount}</span>
                                    ) : null}
                                </div>
                                <div className="profile-main-button-wrapper">
                                    <button className="profile-main-button" type="submit" disabled={loading}>
                                        <span className="profile-main-button-txt">{t('Deposit')}</span>

                                    </button>
                                </div>
                            </div>

                        </Form>
                        )}
                </Formik>}
                <div style={{padding:'2rem 0', fontSize:'1.4rem'}} dangerouslySetInnerHTML={{__html:t('transfer-withdraw-info-bottom-text') || ''}}>

                </div>
            </>

    )
}

export default DepositForm
