import React, {useState} from "react";
import {EmptyProps} from "../../shared/types";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {set} from "lodash";
import {useOutsideClick} from "../../hooks/useOnClickOutside";

type Option = {
    value: any,
    label: string
}
type CustomSelectProps = {
    options: Option[],
    onValueChanged: (value: any) => void
}

export const CustomSelect: React.FC<CustomSelectProps> = ({options, onValueChanged}) => {
    const {t} = useTranslation()
    const [selected, setSelected] = useState(options[0])
    const [opened, setOpened] = useState(false)
    function handleSelect(op:Option){
        setSelected(op)
        onValueChanged(op.value)
        setOpened(false)
    }
    const ref = useOutsideClick(()=>{
        setOpened(false)
    })
    return (
        <div className="phone-dropdown-wrapper" ref={ref}>
            <div className={classNames("phone-dropdown-input-container",{active:opened})}>
                <input type="text" placeholder="+880" value={selected.label} disabled className="profile-input"/>
                <i className="icon-down-sr"  onClick={options.length > 1 ? ()=>setOpened(!opened) : undefined}/>
            </div>
            {options.length > 1 ? <div className="phone-dropdown-container">
                <div className="phone-dropdown-content">
                    {options.map((op) => (
                        <div className="phone-dropdown-row" key={op.value} onClick={() => handleSelect(op)}>
                            <span className="phone-dropdown-row-txt">{t(op.label)}</span>
                        </div>
                    ))}

                </div>
            </div> : null}
        </div>
    )
}