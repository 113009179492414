import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { getUser, login, logout, verifyOtp } from "../../api/rafflesApi";
import { RaffleStatus, UserState, UserLoginData, VerifyOtpData } from "types";
import { addTicketAsync } from "../basket/basketSlice";
import { showToast } from "../../components/ToastContainer";

export const loginAsync = createAsyncThunk(
  "user/login",
  async (data: UserLoginData) => {
    const response = await login(data);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getUserAsync = createAsyncThunk("user/getUser", async () => {
  const response = await getUser();
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const logoutAsync = createAsyncThunk("user/logout", async () => {
  const response = await logout();
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const verifyOtpAsync = createAsyncThunk(
  "user/verifyOtp",
  async (verifyOtpData: VerifyOtpData, thunkAPI) => {
    let search = window.localStorage.getItem("search");
    let terminalId = null;
    if (search) {
      let urlSearchP = new URLSearchParams(search);
      terminalId = urlSearchP.get("terminalId") || null;
    }

    const response = await verifyOtp({ ...verifyOtpData, terminalId });
    if (response.code) {
      throw new Error(response.message);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

let urlSearchParams = new URLSearchParams(window.location.search);
export const initialState: UserState = {
  user: null,
  otpUserId: null,
  status: "idle",
  terminalId: urlSearchParams.get("terminalId") || null,
  terminalName: urlSearchParams.get("terminalName") || "",
  terminalMode: urlSearchParams.get("terminalMode") === "1",
};
window.localStorage.setItem("search", window.location.search);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {})
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.otpUserId = action.payload.otpUserId;
      })

      .addCase(loginAsync.rejected, (state, action) => {
        showToast("error", action.error.message || "failed to login");
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.otpUserId = null;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.user = null;
      })
      .addCase(addTicketAsync.fulfilled, (state, action) => {
        // if(state.user)
        //     state.user.balance = action.payload.balance
      })
      .addCase(logoutAsync.rejected, (state, { type, error, meta }) => {
        console.log(error);
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.user;
export const selectUserCurrency = (state: RootState) =>
  state.user.user?.currency || "";
export const selectUserCurrencyTemplate = (state: RootState) =>
  state.user.user?.currencyTemplate || "";
export const selectTerminalId = (state: RootState) => state.user.terminalId;
export const selectTerminalName = (state: RootState) => state.user.terminalName;
export const selectTerminalMode = (state: RootState) => state.user.terminalMode;
export const selectOtpUserId = (state: RootState) => state.user.otpUserId;
export const selectUpcomingRaffles = (state: RootState) =>
  state.root.raffles
    .filter((raf) => raf.status === RaffleStatus.UPCOMING)
    .slice()
    .sort((a, b) => (a.startDate < b.startDate ? -1 : 1))
    .slice(0, 3);
export const selectRafflesStatus = (state: RootState) => state.root.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectUser(getState());
    // if (currentValue.id % 2 === 1) {
    //
    // }
  };

export default userSlice.reducer;
