import React, { useEffect } from "react";
import { EmptyProps, RaffleBasket, UserRedirect } from "types";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearRaffleBasket,
  selectRaffleBasket,
} from "../../features/basket/basketSlice";
import { selectRaffleData } from "../../features/raffles/rafflesSlice";

const RaffleBucket: React.FC<EmptyProps> = () => {
  const params = useParams() as { raffleId: string };
  const { status } = useAppSelector<RaffleBasket | null>((state) =>
    selectRaffleBasket(state, params.raffleId)
  ) as RaffleBasket;
  const raffleData = useAppSelector((state) =>
    selectRaffleData(state, parseInt(params.raffleId || "0"))
  );

  if (!params.raffleId || !raffleData) {
    return <Navigate to={"/home"} replace />;
  }
  if (status === "success") {
    return <Navigate to={`/raffles/${params.raffleId}/info`} />;
  }
  return <Outlet />;
};

export default RaffleBucket;
