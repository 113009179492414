import { Navigate, NavLink, useNavigate } from "react-router-dom";
import useBasket from "../hooks/useBasket";
import React, { useEffect, useMemo, useState } from "react";
import { NftCard, NftCardCmpProps } from "types";
import { getRaffleNFTs } from "../api/rafflesApi";
import classNames from "classnames";
import { useAppDispatch } from "../app/hooks";
import { selectNFT, setTicketNfts } from "../features/basket/basketSlice";
import CurrentRaffleTopBanner from "../components/CurrentRaffleTopBanner";

import { useInView } from "react-intersection-observer";
import { useOutsideClick } from "../hooks/useOnClickOutside";
import Loader from "../components/Loader";
import AmountView from "../components/common/AmountView";
import ValidationError from "../components/ValidationError";
import useValidationError from "../hooks/useValidationError";
import { useTranslation } from "react-i18next";
import shuffle from "lodash/shuffle";
type Props = {};

const Collections: React.FC<Props> = () => {
  const { raffleTickets, raffleId, selectedNftCount, raffleData } = useBasket();

  const selectedNftIds = useMemo(() => {
    return raffleTickets ? raffleTickets?.nfts.map((rt) => rt.id) : [];
  }, [raffleTickets]);
  const [nfts, setNfts] = useState<NftCard[]>([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(Math.floor(Math.random() * 100));
  const { message, setMessage } = useValidationError();
  const [filter, setFilter] = useState(selection[0]);
  const [loadingNfts, setLoadingNfts] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  function handleSubmitNfts() {
    if (selectedNftCount !== raffleTickets?.tickets.length) {
      setMessage(
        `Your purchase of ${
          raffleTickets.tickets.length
        } ticket you eligible to enter the Raffle Draw. Choose ${
          raffleTickets.tickets.length - selectedNftCount
        } nfts for each line to participate.`
      );
    } else {
      navigate(`/raffles/${raffleId}/make-payment`);
    }
  }
  function selectRandomNfts() {
    // let nftIds = nfts.map(nf)

    dispatch(
      setTicketNfts({
        nfts: shuffle(nfts.slice()).slice(0, raffleTickets.tickets.length),
        raffleId,
      })
    );
  }
  useEffect(() => {
    if (inView) setPage((page) => ++page);
  }, [inView]);
  useEffect(() => {
    setLoadingNfts(true);

    getRaffleNFTs(raffleId, page, filter.value).then((data) => {
      setNfts((nfts) => [...nfts, ...data]);
      setLoadingNfts(false);
    });
  }, [page]);

  useEffect(() => {
    setNfts([]);
  }, [filter]);

  if (!raffleData) return <Navigate to={"/home"} />;

  return (
    <div className="collections-wrapper">
      <CurrentRaffleTopBanner data={raffleData} />
      <div className="container">
        <div className="collections-header">
          <div className="ticket-t-back-wrp">
            <NavLink
              to={`/raffles/${raffleId}/tickets`}
              className="ticket-t-back-block-inner"
            >
              <i className="icon-arrow-left" />
              <div className="ticket-t-back-txt">{t("Back")}</div>
            </NavLink>
          </div>

          <div className="collections-header-content">
            <div className="collections-h-txt-nft">
              <p className="collections-h-txt">
                {t("Choose Your NFTs")}
                {/*<span><AmountView*/}
                {/*value={raffleData.ticketPrice}/></span>*/}
              </p>
            </div>
            <CollectionFilter
              value={filter}
              onValueChanged={(value) => setFilter(value)}
            />
            <div className="collections-h-btn-go">
              <div className="collections-h-btn-go-wrp">
                {/*Miro jan stex dir en button@ es dzem*/}
                {nfts.length ? (
                  <button
                    className="btn-bottom type-1"
                    onClick={selectRandomNfts}
                  >
                    {t("Select Randomly")}
                  </button>
                ) : null}

                <button
                  onClick={handleSubmitNfts}
                  className={classNames("btn-bottom type-2")}
                >
                  {t("Go To Checkout")}
                </button>
              </div>
            </div>
            <div className="collections-h-selected-nft">
              <p className="collections-h-selected-nft-txt">
                {t("Selected NFT’s")} {selectedNftCount}/
                {raffleTickets?.tickets.length}
              </p>
            </div>
          </div>
          <ValidationError message={message} />
        </div>

        <div className="collections-list">
          {nfts.map((nft) => (
            <NftCardCmp
              key={nft.id}
              raffleId={raffleId}
              data={nft}
              selected={selectedNftIds.includes(nft.id)}
            />
          ))}
        </div>
        <Loader />

        <div className="ticket-t-btn-wrp" ref={ref}>
          {/*<div className="ticket-t-btn-clear-inner">*/}
          {/*    <button className="btn-bottom type-1">Randomise all lines</button>*/}
          {/*    <button className="btn-bottom type-1"><i className="icon-close-n"/> Clear all lines</button>*/}
          {/*</div>*/}
          {/*/!*<button className="btn-bottom type-2">Next</button>*!/*/}
          {/*<NavLink to={`/raffles/${raffleId}/make-payment`} className="btn-bottom type-2">Next</NavLink>*/}
        </div>
      </div>
    </div>
  );
};

const NftCardCmp: React.FC<NftCardCmpProps> = ({
  data,
  selected,
  raffleId,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  function selectNFt() {
    dispatch(
      selectNFT({
        ...data,
        raffleId,
      })
    );
  }

  return (
    <div
      className={classNames("collection-el", { active: selected })}
      onClick={selectNFt}
    >
      <div className="collections-el-thumbnail">
        <span />
        <img src={data.media.small} alt="" />
      </div>
      <div className="collection-author-row">
        <img src={data.created_by.logo_url} alt="" />
        <p className="collection-author-name">{data.name}</p>
      </div>
      <p className="collection-number">#{data.id}</p>
      <div className="collection-el-price-block">
        <span className="collection-el-price-label">{t("Price")}</span>
        <p className="collection-el-price">
          <AmountView value={data.price} />
        </p>
      </div>
    </div>
  );
};

const selection = [
  {
    label: "New",
    value: "desc",
  },
  {
    label: "Old",
    value: "asc",
  },
];
const CollectionFilter: React.FC<{
  value: { label: string; value: string };
  onValueChanged: (value: { label: string; value: string }) => void;
}> = ({ onValueChanged, value: filterValue }) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState(filterValue);
  const ref = useOutsideClick(() => {
    setOpened(false);
  });
  useEffect(() => {
    onValueChanged(value);
  }, [value]);
  return (
    <div className="collections-h-filter" ref={ref}>
      <div
        className={classNames("collections-h-filter-content", { open: opened })}
      >
        {" "}
        {/*add/remove class open*/}
        <div
          className="collections-h-filter-inner"
          onClick={() => setOpened(!opened)}
        >
          <p className="collections-h-filter-txt">{value.label}</p>
          <div className="collections-h-filter-icon">
            <i className="icon-arrow-top" />
          </div>
        </div>
        <div className="collections-h-filter-open">
          <div className="collections-h-filter-open-holder">
            {selection.map((s) => (
              <p
                key={s.label}
                className="collections-h-filter-open-txt"
                onClick={() => {
                  setValue(s);
                  setOpened(false);
                }}
              >
                {s.label}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collections;
