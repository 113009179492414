import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectLatestRaffles,
  selectRaffles,
} from "../../features/raffles/rafflesSlice";
import { RaffleProps } from "types";
import dayjs from "dayjs";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import timerM from "../../assets/images/timerM.png";
import calendarNumberM from "../../assets/images/calendarNumberM.png";
import calendarM from "../../assets/images/calendarM.png";
import sStarM from "../../assets/images/ka.png";
import cardMiddle1 from "../../assets/images/cardMiddle1.png";
import cardMiddle2 from "../../assets/images/cardMiddle2.png";
import cardMiddle3 from "../../assets/images/cardMiddle3.png";
import cardMiddle4 from "../../assets/images/cardMiddle4.png";

import { useTranslation } from "react-i18next";

type Props = {};

type RafflesFilteredData = {
  id: number;
  prizeFund: number;
  type: number;
};

const images = [cardMiddle1, cardMiddle2, cardMiddle3, cardMiddle4];

const LatestDrawResults: React.FC<Props> = () => {
  const latestDraws = useSelector(selectLatestRaffles);
  let navigate = useNavigate();
  const raffles = useSelector(selectRaffles);
  const [rafflesData, setRafflesData] = useState<RafflesFilteredData[]>([]);
  console.log("raffles", raffles);

  const { t } = useTranslation();

  useEffect(() => {
    const uniqueTypes = raffles.reduce((acc: any, curr: any) => {
      const { type, prizeFund, id } = curr;
      if (!acc[type]) {
        acc[type] = { type, prizeFund, id };
      }
      return acc;
    }, {});

    setRafflesData(Object.values(uniqueTypes));
  }, [raffles]);

  console.log("rafflesData", rafflesData);

  function handleJoinButtonClick(id: number) {
    navigate("/raffles/" + id + "/tickets");
  }

  return (
    <>
      <div
        className="raffle-middle-cards container"
        // style={{ display: "none" }}
      >
        <div className="raffle-middle-container">
          <p className="title-top-txt">{t("Raffles")}</p>
          <div className="raffle-cards-row-wrapper">
            {rafflesData.map((raffle: any, ind) => {
              return (
                <div className="raffle-row-card-item">
                  <div className="raffle-card-middle">
                    <div className="raffle-middle-card-img-container">
                      <img
                        src={images[ind]}
                        alt="#"
                        className="raffle-middle-card-img"
                      />
                      <div className="raffle-middle-win-row-container">
                        <p className="raffle-middle-win-txt">
                          {" "}
                          {ind === 0
                            ? t("Fast wins")
                            : ind === 1
                            ? t("Lucky wins")
                            : ind === 2
                            ? t("Super WINS")
                            : t("GRAND WINS")}
                        </p>
                      </div>
                    </div>
                    <div className="raffle-card-title-img">
                      <img
                        src={sStarM}
                        alt="#"
                        className="raffle-card-min-img"
                      />
                      <p className="raffle-middle-card-txt">
                        {ind === 0
                          ? t("Every 5 Min")
                          : ind === 1
                          ? t("Hourly")
                          : ind === 2
                          ? t("Daily")
                          : t("Weekly")}
                      </p>
                    </div>
                    <p className="raffle-middle-card-price">
                      {raffle.prizeFund ? `${raffle.prizeFund}AED` : ''}
                    </p>
                  </div>
                  <button
                    className="btn-bottom type-1"
                    onClick={() => handleJoinButtonClick(raffle.id)}
                  >
                    {t("Join Now")}{" "}
                  </button>
                </div>
              );
            })}

            {/* <div className="raffle-row-card-item">
              <div className="raffle-card-middle">
                <div className="raffle-middle-card-img-container">
                  <img
                    src={cardMiddle1}
                    alt="#"
                    className="raffle-middle-card-img"
                  />
                  <div className="raffle-middle-win-row-container">
                    <p className="raffle-middle-win-txt">{t("Fast wins")}</p>
                  </div>
                </div>
                <div className="raffle-card-title-img">
                  <img src={sStarM} alt="#" className="raffle-card-min-img" />
                  <p className="raffle-middle-card-txt">{t("Every 5 Min")}</p>
                </div>
                <p className="raffle-middle-card-price">100,000 AED</p>
              </div>
              <button className="btn-bottom type-1">{t("Join Now")} </button>
            </div>
            <div className="raffle-row-card-item">
              <div className="raffle-card-middle">
                <div className="raffle-middle-card-img-container">
                  <img
                    src={cardMiddle2}
                    alt="#"
                    className="raffle-middle-card-img"
                  />
                  <div className="raffle-middle-win-row-container">
                    <p className="raffle-middle-win-txt">{t("Lucky wins")}</p>
                  </div>
                </div>
                <div className="raffle-card-title-img">
                  <img src={timerM} alt="#" className="raffle-card-min-img" />
                  <p className="raffle-middle-card-txt">{t("Every 5 Min")}</p>
                </div>
                <p className="raffle-middle-card-price">100,000 AED</p>
              </div>
              <button className="btn-bottom type-1">{t("Join Now")}</button>
            </div>
            <div className="raffle-row-card-item">
              <div className="raffle-card-middle">
                <div className="raffle-middle-card-img-container">
                  <img
                    src={cardMiddle3}
                    alt="#"
                    className="raffle-middle-card-img"
                  />
                  <div className="raffle-middle-win-row-container">
                    <p className="raffle-middle-win-txt">{t("Super WINS")}</p>
                  </div>
                </div>
                <div className="raffle-card-title-img">
                  <img
                    src={calendarNumberM}
                    alt="#"
                    className="raffle-card-min-img"
                  />
                  <p className="raffle-middle-card-txt">{t("Daily")}</p>
                </div>
                <p className="raffle-middle-card-price">100,000 AED</p>
              </div>
              <button className="btn-bottom type-1">{t("Join Now")}</button>
            </div>
            <div className="raffle-row-card-item">
              <div className="raffle-card-middle">
                <div className="raffle-middle-card-img-container">
                  <img
                    src={cardMiddle4}
                    alt="#"
                    className="raffle-middle-card-img"
                  />
                  <div className="raffle-middle-win-row-container">
                    <p className="raffle-middle-win-txt">{t("GRAND WINS")}</p>
                  </div>
                </div>
                <div className="raffle-card-title-img">
                  <img
                    src={calendarM}
                    alt="#"
                    className="raffle-card-min-img"
                  />
                  <p className="raffle-middle-card-txt">{t("Weekly")}</p>
                </div>
                <p className="raffle-middle-card-price">100,000 AED</p>
              </div>
              <button className="btn-bottom type-1">{t("Join Now")}</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="latest-draw-results container">
        <h2 className="latest-draw-title">{t("Results")}</h2>

        <div className="latest-draw-result-holder">
          {/* Add class // win // loose // latest-draw-result */}

          {latestDraws.map((raffle) => (
            <LatestDraw key={raffle.id} data={raffle} />
          ))}
        </div>
      </div>

      <div className="raffle-winners-wrapper container">
        <div className="raffle-winners-container">
          <p className="title-top-txt">{t("Raffle Winners")}</p>
          <div className="raffle-subtitle-container">
            <p className="raffle-top-subtitle">
              {t(
                "Check out here for updates on our lucky winners every hour. Watch the video and join their league"
              )}
              .
            </p>
          </div>
          <div className="raffle-winners-cards-wrapper">
            <div className="raffle-winner-card-container">
              <iframe
                width="100%"
                height="801"
                src="https://www.youtube.com/embed/1wa7J7TV-_g"
                title="Congratulations to the 3 winners of our daily raffle!"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="raffle-winner-card-container">
              <iframe
                width="100%"
                height="801"
                src="https://www.youtube.com/embed/xpol-3d-onk"
                title="Congratulations to the 3 winners of our daily raffle!"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LatestDraw: React.FC<RaffleProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("latest-draw-result win")}>
      {/*add class win / loose*/}
      <div className="latest-draw-result-info">
        <div className="latest-draw-result-date">
          <p className="latest-draw-result-date-txt">
            {dayjs(data.startDate * 1000).format("DD.MM.YYYY")}
          </p>
        </div>
        <div className="latest-draw-result-status-holder">
          <p className="latest-draw-result-status-title">{t("Status")}:</p>
          <p className="latest-draw-result-status">
            {data.joined ? t("Joined") : t("Not Joined")}
          </p>
        </div>
      </div>
      <div className="latest-epic-draw">
        <p className="latest-epic-draw-title">
          {t("Raffle No")} {data.id}
        </p>
        {/*<p className="latest-epic-draw-subtitle">{t('Matching')} -/6 </p>*/}
      </div>
      <div className="latest-draw-ticket">
        <div className="latest-draw-ticket-info">
          <div className="latest-draw-ticket-info-row">
            <p className="latest-draw-ticket-num-title">{t("Time")} </p>
            <p className="latest-draw-ticket-num">
              {dayjs(data.startDate * 1000).format("HH:mm")}
            </p>
          </div>
          <div className="latest-draw-ticket-info-row">
            {/*<div className="latest-draw-ticket-info-col">*/}
            {/*    <p className="latest-draw-ticket-info-col-title">YOU CHOOSE</p>*/}
            {/*    <p className="latest-draw-ticket-info-col-nums">{data.result.join(', ')}</p>*/}
            {/*</div>*/}

            <div className="latest-draw-ticket-info-col">
              <p className="latest-draw-ticket-info-col-title">{t("Result")}</p>
              <p className="latest-draw-ticket-info-col-nums">
                {data.result.map((id, idx) => (
                  <span key={idx} className="latest-draw-ticket-info-col-num">
                    {id}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="results-btn-row">
        <NavLink to={`/raffles/${data.id}/info`} className="results-btn">
          {t("Results")}
        </NavLink>
      </div>
    </div>
  );
};

export default LatestDrawResults;
