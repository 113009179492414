import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  addRandomTicket,
  selectRaffleBasket,
} from "../features/basket/basketSlice";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { RaffleBasket } from "types";
import { selectRaffleData } from "../features/raffles/rafflesSlice";

export default function useBasket() {
  const params = useParams() as { raffleId: string };
  const dispatch = useAppDispatch();
  const raffleId = parseInt(params.raffleId || "0");
  const raffleTickets = useAppSelector<RaffleBasket | null>((state) =>
    selectRaffleBasket(state, params.raffleId)
  ) as RaffleBasket;
  const raffleData = useAppSelector((state) =>
    selectRaffleData(state, raffleId)
  );

  const selectedNftCount = useMemo(() => {
    return raffleTickets?.nfts.length;
  }, [raffleTickets]);
  useEffect(() => {
    if (raffleId && !raffleTickets?.tickets.length) {
      dispatch(addRandomTicket({ raffleId }));
    }
  }, [raffleTickets, raffleId]);

  return {
    raffleId,
    selectedNftCount,
    raffleTickets,
    raffleData,
  };
}
