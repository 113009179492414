import ticketImg from "../../assets/images/nft-image.png";
import React, { useEffect, useState } from "react";
import { EmptyProps, MyTicket, RaffleResultTicket } from "types";
import { getMyTickets } from "../../api/rafflesApi";
import Loader from "../../components/Loader";
import classNames from "classnames";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import usePagination from "../../hooks/usePagination";
import AmountView from "../../components/common/AmountView";
type Props = {};

const TicketHistory: React.FC<Props> = () => {
  const [tickets, setTickets] = useState<MyTicket[]>([]);

  const {
    page,
    changeToPrevPage,
    itemsPerPage,
    changeToNextPage,
    changeItemsPerPage,
  } = usePagination(5);

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  async function getTickets(page: number) {
    let tickets = await getMyTickets(page, itemsPerPage);
    setTickets((t) => [...tickets]);
  }

  useEffect(() => {
    getTickets(page).then(() => {
      setLoading(false);
    });
  }, [page, itemsPerPage]);

  return (
    <div className="ticket-history-wrp">
      <div className="b-s-header">
        <p className="b-s-header-txt">{t("Ticket History")}</p>
      </div>
      {loading ? <Loader /> : null}
      <div className="ticket-history-content">
        {tickets.map((t) => (
          <MyTicketCmp data={t} key={t.ticketId} />
        ))}
        <Pagination
          onNextButtonClick={changeToNextPage}
          onItemsPerPageChange={changeItemsPerPage}
          onPrevButtonClick={changeToPrevPage}
          itemsPerPage={itemsPerPage}
          page={page}
          nextButtonEnabled={!tickets.length}
        />
      </div>
    </div>
  );
};

const MyTicketCmp: React.FC<{ data: MyTicket }> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="ticket-history-content-block">
      <div className="ticket-history-content-left">
        <div className="ticket-history-left-info">
          <div className="ticket-history-left-info-img">
            <img src={data.nft.media.small} alt="" />
          </div>
          <div className="ticket-history-left-info-holder">
            <p className="ticket-history-left-info-txt">
              {t(data.raffle.name)}: <span>#{data.raffle.id}</span>
            </p>
            <p className="ticket-history-left-info-txt">
              {t("Date")}:{" "}
              <span>{dayjs(data.atDate).format("DD.MM.YYYY HH:mm")}</span>
            </p>
            <p className="ticket-history-left-info-txt">{data.nft.name}</p>
            <p className="ticket-history-left-info-txt">
              {t("Ticket Id")}: <span>#{data.ticketId}</span>
              <NavLink to={"/raffles/" + data.raffle.id + "/info"}>
                {t("Details")}
              </NavLink>
            </p>
          </div>
        </div>
        {/*<div className="ticket-history-left-btn">*/}
        {/*    <button className="btn-bottom type-2">Play again</button>*/}
        {/*</div>*/}
      </div>
      <div className="ticket-history-content-center">
        <p className="ticket-history-number-txt">{t("Numbers")}</p>
        <div className="ticket-history-circle-wrp">
          {data.numbers.map((n) => (
            <div
              key={n}
              className={classNames("tick-t-block-m-circle", {
                active: data.raffle.result.includes(n),
              })}
            >
              <span className="tick-t-block-m-number">{n}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="ticket-history-content-right">
        <p className="ticket-history-number-txt">{t("Status")}</p>
        <p className="ticket-history-id">
          {data.status === 3 ? (
            <AmountView value={data.win} />
          ) : data.status === 4 ? (
            t("Lose")
          ) : (
            t("Pending")
          )}
        </p>
      </div>
    </div>
  );
};
export default TicketHistory;
