import React, { useEffect, useMemo, useState } from "react";
import { Transaction } from "types";
import { getMyTransactions } from "../../api/rafflesApi";
import dayjs from "dayjs";
import classNames from "classnames";
import AmountView from "../../components/common/AmountView";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import usePagination from "../../hooks/usePagination";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../features/user/userSlice";
import WithdrawAssets from "../../components/WithdrawAssets";
import DropDownWrapper from "../../components/DropDownWrapper";
import { CustomSelect } from "../../components/common/CustomSelect";

type Props = {};

const BalanceSummary: React.FC<Props> = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const {
    page,
    changeToPrevPage,
    itemsPerPage,
    changeToNextPage,
    changeItemsPerPage,
  } = usePagination(5);
  const { t } = useTranslation();

  async function getTransaction() {
    let trxs = await getMyTransactions(page, itemsPerPage);
    setTransactions(trxs);
  }

  const user = useAppSelector(selectUser);

  useEffect(() => {
    getTransaction();
  }, [page, itemsPerPage]);

  return (
    <div className="balance-summary-wrapper">
      <div className="b-s-header">
        <p className="b-s-header-txt">{t("Balance summary")}</p>
      </div>
      <div className="b-s-bal-row" style={{ display: "none" }}>
        <div className="b-s-bal-row-columns-content">
          <div className="b-s-bal-row-columns">
            <div className="b-s-bal-row-columns-min-cont">
              <p className="b-s-bal-row-txt">{user?.firstName}</p>
              <p className="b-s-bal-row-txt">
                balance:{" "}
                <span className="b-s-bal-row-txt pink">
                  <AmountView value={user?.balance || 0} />
                </span>
              </p>
            </div>
            <div className="b-s-bal-row-columns-button-cont">
              <button className="b-s-bal-row-button">Add Credit</button>
            </div>
          </div>
        </div>
        <DropDownWrapper
          buttonText={"Transfer/Withdraw"}
          label={
            <div className="b-s-bal-row-columns-min-cont">
              <p className="b-s-bal-row-txt">{user?.firstName}</p>
              <p className="b-s-bal-row-txt">
                balance:{" "}
                <span className="b-s-bal-row-txt pink">
                  <AmountView value={user?.balance || 0} />
                </span>
              </p>
            </div>
          }
        >
          <WithdrawAssets />
        </DropDownWrapper>
      </div>
      <div className="profile-filters-row" style={{ display: "none" }}>
        <div className="profile-filter-item-container">
          <div className="profile-filter-item active">
            <i className="icon-down-sr" />
            <input type="text" className="profile-input" placeholder="ID" />
          </div>
          <div className="profile-filter-dropdown-container">
            <div className="profile-filters-drop-cont">
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-filter-item-container">
          <div className="profile-filter-item">
            <i className="icon-down-sr" />
            <input
              type="text"
              className="profile-input"
              placeholder="Date/Time"
            />
          </div>
          <div className="profile-filter-dropdown-container">
            <div className="profile-filters-drop-cont">
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-filter-item-container">
          <div className="profile-filter-item">
            <i className="icon-down-sr" />
            <input type="text" className="profile-input" placeholder="Type" />
          </div>
          <div className="profile-filter-dropdown-container">
            <div className="profile-filters-drop-cont">
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
              <div className="profile-filters-drop-row">
                <span className="phone-dropdown-row-txt">123</span>
              </div>
            </div>
          </div>
        </div>
        <button className="btn-bottom type-2">Apply</button>
      </div>

      <div className="b-s-table">
        <div className="b-s-table-inner">
          <div className="b-s-table-rows">
            <p className="b-s-table-rows-columns type1">#</p>
            <p className="b-s-table-rows-columns type2">{t("Date/Time")}</p>
            <p className="b-s-table-rows-columns type3">{t("Type")}</p>
            <p className="b-s-table-rows-columns type4">{t("Description")}</p>
            <p className="b-s-table-rows-columns type1">{t("Amount")}</p>
            {/*<p className="b-s-table-rows-columns type5">*/}
            {/*    Total Balance*/}
            {/*</p>*/}
          </div>
          {transactions.map((tr) => (
            <TransactionCmp data={tr} key={tr.id} />
          ))}
        </div>
      </div>
      <Pagination
        onNextButtonClick={changeToNextPage}
        onItemsPerPageChange={changeItemsPerPage}
        onPrevButtonClick={changeToPrevPage}
        itemsPerPage={itemsPerPage}
        nextButtonEnabled={!transactions.length}
        page={page}
      />
      <div className="pagination-wrapper" style={{ display: "none" }}>
        <div className="pagination-txt-block">
          <p className="pagination-txt-page">{t("Items per page")}</p>
          <div className="pagination-arrow-block">
            {" "}
            {/*add/remove class open*/}
            <span className="pagination-arrow-count">1</span>
            <i className="icon-arrow-top" />
            <div className="pagination-count-open-block">
              <span>1</span>
              <span>1</span>
              <span>1</span>
              <span>1</span>
            </div>
          </div>
        </div>
        <p className="pagination-count-block">1-20</p>
        <div className="pagination-icon-block">
          <i className="icon-arrow-left" />
          <i className="icon-arrow-right" />
        </div>
      </div>
    </div>
  );
};

const TransactionCmp: React.FC<{ data: Transaction }> = ({ data }) => {
  const typeText = useMemo(() => {
    switch (data.type) {
      case 1:
        return "Order";
      case 2:
        return "Credit";
      default:
        return "---";
    }
  }, [data.type]);
  return (
    <div className="b-s-table-rows">
      <p className="b-s-table-rows-columns type1">#{data.id}</p>
      <p className="b-s-table-rows-columns type2">
        {dayjs(data.atDate).format("DD.MM.YYYY HH.mm")}
      </p>
      <p className="b-s-table-rows-columns type3">{typeText}</p>
      <p className="b-s-table-rows-columns type4">
        <span className="b-s-table-rows-pink">{data.info}</span>
      </p>
      <p
        className={classNames(
          "b-s-table-rows-columns type1",
          data.type === 1 ? "orange" : "green"
        )}
      >
        {data.type === 1 ? "-" : ""}
        <AmountView value={data.amount} />
      </p>
    </div>
  );
};
export default BalanceSummary;
