import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { getRaffles, joinRaffle, raffleInfo } from "api/rafflesApi";
import { RaffleInfo, RaffleInfoData, RafflesState, RaffleStatus } from "types";
import { addTicketAsync } from "../basket/basketSlice";

export const joinRaffleAsync = createAsyncThunk(
  "raffles/joinRaffle",
  async (id: number) => {
    const response = await joinRaffle(id);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getRafflesASync = createAsyncThunk(
  "raffles/getRaffles",
  async () => {
    const response = await getRaffles();

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getRaffleInfo = createAsyncThunk(
  "raffles/getRaffleInfo",
  async (data: RaffleInfoData) => {
    const response = await raffleInfo(data);
    // The value we return becomes the `fulfilled` action payload
    return response as RaffleInfo;
  }
);

export const initialState: RafflesState = {
  raffles: [],
  status: "idle",
  showUpcomingRaffleInfo: true,
  totalInfo: {
    totalWins: 0,
    id: 0,
    tickets: 0,
  },
  raffleInfo: {},
};

export const rafflesSlice = createSlice({
  name: "raffles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    hideUpcomingRaffleInfo: (state) => {
      state.showUpcomingRaffleInfo = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(joinRaffleAsync.pending, (state) => {})
      .addCase(joinRaffleAsync.fulfilled, (state, action) => {
        console.log(action);
      })
      
      .addCase(getRaffleInfo.fulfilled, (state, action) => {
        state.raffleInfo[action.payload.id] = action.payload;
      })

      .addCase(getRafflesASync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getRafflesASync.fulfilled, (state, action) => {
        state.status = "success";
        state.raffles = action.payload.raffles;
        state.totalInfo = action.payload.totalInfo;
      })
      .addCase(addTicketAsync.fulfilled, (state, action) => {
        const raffle = state.raffles.find(
          (r) => r.id === action.payload.trxInfo.raffleId
        );
        if (raffle) raffle.joined = true;
      })
      .addCase(getRafflesASync.rejected, (state, { type, error, meta }) => {
        console.log(error);
        state.status = "failed";
      });
  },
});

export const { hideUpcomingRaffleInfo } = rafflesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRaffles = (state: RootState) => state.root.raffles;
export const selectTotalWins = (state: RootState) =>
  state.root.totalInfo.totalWins;
export const selectTotalTickets = (state: RootState) =>
  state.root.totalInfo.tickets;
export const selectRaffleData = (state: RootState, raffleId: number) =>
  state.root.raffles.find((r) => r.id === raffleId);
export const selectUpcomingRaffles = (state: RootState) =>
  state.root.raffles
    .filter((raf) => raf.status === RaffleStatus.UPCOMING)
    .slice()
    .sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
export const selectLatestRaffles = (state: RootState) =>
  state.root.raffles
    .filter((raf) => raf.status !== RaffleStatus.UPCOMING)
    .slice()
    .sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
export const selectRafflesStatus = (state: RootState) => state.root.status;
export const selectRaffleInfo = (state: RootState, raffleId: string) =>
  state.root.raffleInfo[raffleId];

export default rafflesSlice.reducer;
