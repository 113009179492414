import React, {FormEvent, useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {
    StripePaymentElementOptions
} from "@stripe/stripe-js";
import classNames from "classnames";
import {stripePaymentSuccess} from "../features/basket/basketSlice";
import {useAppDispatch} from "../app/hooks";
import {showToast} from "./ToastContainer";
import {useTranslation} from "react-i18next";

const pelOpt = {
    layout:"tabs"
} as StripePaymentElementOptions
const CheckoutForm:React.FC<{raffleId:number, onLoadSuccess:()=>void, onLoadError:()=>void, onSuccess:()=>void}> = ({raffleId, onSuccess,onLoadSuccess, onLoadError}) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch()
    const [message, setMessage] = useState('');
    const [stripeLoaded, setStripeLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation()


    const handleSubmit = async (e:FormEvent) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "",
            },
            redirect: "if_required"
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if(error) {
            if (error?.type === "card_error" || error?.type === "validation_error") {
                showToast('error',error.message || '')

            }
            else {
                showToast('error',"An unexpected error occurred.")

            }
        }
        else if(paymentIntent?.status === "succeeded"){
            onSuccess()
        }
        else{
            showToast('error',"An unexpected error occurred. " + paymentIntent?.status)

        }

        setIsLoading(false);
    };

    return (
        <div className="payment-input-wrp" style={{paddingBottom:'2rem'}}>

        <form id="payment-form" onSubmit={handleSubmit}>

            <PaymentElement options={pelOpt}
                            onLoadError={()=>onLoadError()}
                            onReady={()=>{setStripeLoaded(true);
                                onLoadSuccess()}}/>

            {stripeLoaded ? <div style={{paddingTop:"2rem"}}>
                 <button type='submit'  className={classNames('pay-footer-next-btn',{disabled:isLoading || !stripe || !elements})}
                    >{isLoading ? t("Processing") : t("Pay now")}
            </button>
            </div> : null}
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{t(message)}</div>}
        </form>
        </div>
    );
}

export default CheckoutForm