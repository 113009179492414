
import numeral from "numeral";
import {useAppSelector} from "../../app/hooks";
import {selectUserCurrency, selectUserCurrencyTemplate} from "../../features/user/userSlice";
import React from "react";
import {template} from "lodash";

export type AmountViewProps = {
  value:number
  shouldConvert?:boolean
}

const currencyFormat = '0,0.[00]'
const AmountView:React.FC<AmountViewProps> = ({ value, shouldConvert = false }) => {
  // const currency = useAppSelector(selectUserCurrency);
  const template = useAppSelector(selectUserCurrencyTemplate);
  if(typeof value === 'string')
    return value
  let val = numeral(value).format(currencyFormat);
  return <>{template.replace("#", val)}</>;
}

export const formatValue = (value:number,template:string)=>{
  let val = numeral(value).format(currencyFormat);
  return template.replace("#", val)
}
export default AmountView