import React from "react";
import imgCartPage from "../../assets/images/nft-image.png";
import { useAppSelector } from "../../app/hooks";
import { selectBasket } from "../../features/basket/basketSlice";
import { EmptyProps } from "types";

type Props = {};

const RecurringPurchases: React.FC<Props> = () => {
  const basket = useAppSelector(selectBasket);

  return (
    <div className="shopping-card-wrp">
      <div className="b-s-header">
        <p className="b-s-header-txt">Shopping Cart</p>
      </div>
      <p className="b-s-small-h-txt">Raffle: Super Saturday</p>

      <div className="shopping-cart-row-wrp">
        <div className="shopping-cart-row-block">
          <div className="shopping-cart-row-holder">
            <CartHeaderRow />
            <div className="shopping-cart-content-row-wrp">
              <div className="shopping-cart-content-row">
                <div className="shopping-cart-content-row-clm type-1">
                  <div className="shopping-cart-check-block active">
                    <i className="icon-done" />
                  </div>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">12198727893</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">Finished</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">3</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">350 AED</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">21.02.2023</p>
                </div>
                <div className="shopping-cart-content-row-clm type-1">
                  <div className="sh-cart-row-clm-icon">
                    <i className="icon-arrow-top" />
                  </div>
                </div>
              </div>
              <div className="shopping-cart-open-content">
                <div className="shopping-cart-open-holder">
                  <div className="pay-ticket-tr">
                    <div className="pay-ticket-td" data-title="ticket details">
                      <div className="pay-ticket-details">
                        <div className="pay-ticket-image">
                          <img src={imgCartPage} alt="" />
                        </div>
                        <div className="pay-ticket-info">
                          <h4 className="pay-ticket-title">Snake collaction</h4>
                          <div className="pay-ticket-date">
                            <h5 className="pay-ticket-date-title">
                              Super Saturday
                            </h5>
                            <p className="pay-ticket-date-paragraph">
                              Saturday, 04.03.2023
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pay-ticket-td"
                      data-title="selected numbers"
                    >
                      <div className="pay-ticket-selected-nums">
                        <span>1</span>
                        <span>2</span>
                        <span>6</span>
                        <span>9</span>
                        <span>23</span>
                        <span>23</span>
                      </div>
                      <div className="pay-ticket-info-action">
                        <span className="pay-ticket-price">aed 10</span>
                        <button
                          type="button"
                          className="pay-ticket-remove icon-close-n"
                        />
                        <button
                          type="button"
                          className="pay-ticket-remove icon-edit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ticket-draw-txt">
            <span>
              Your purchase of 1 ticket you eligible to enter the Raffle Draw.
              Choose 6 numbers in each line to participate.
            </span>
          </div>
          {/*<div className="shopping-cart-btn-wrp">*/}
          {/*    <p className="pay-footer-price-text"><span>Price:&nbsp;</span>AED 30</p>*/}
          {/*    <button type="button" className="pay-footer-next-btn">Make Payment</button>*/}
          {/*</div>*/}
          <div className="shopping-cart-row-holder">
            <CartHeaderRow />
            <div className="shopping-cart-content-row-wrp open">
              <div className="shopping-cart-content-row">
                <div className="shopping-cart-content-row-clm type-1">
                  <div className="shopping-cart-check-block">
                    <i className="icon-done" />
                  </div>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">12198727893</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">Finished</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">3</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">350 AED</p>
                </div>
                <div className="shopping-cart-content-row-clm">
                  <p className="sh-c-content-txt">21.02.2023</p>
                </div>
                <div className="shopping-cart-content-row-clm type-1">
                  <div className="sh-cart-row-clm-icon">
                    <i className="icon-arrow-top" />
                  </div>
                </div>
              </div>
              <div className="shopping-cart-open-content">
                <div className="shopping-cart-open-holder">
                  <div className="pay-ticket-tr">
                    <div className="pay-ticket-td" data-title="ticket details">
                      <div className="pay-ticket-details">
                        <div className="pay-ticket-image">
                          <img src={imgCartPage} alt="" />
                        </div>
                        <div className="pay-ticket-info">
                          <h4 className="pay-ticket-title">Snake collaction</h4>
                          <div className="pay-ticket-date">
                            <h5 className="pay-ticket-date-title">
                              Super Saturday
                            </h5>
                            <p className="pay-ticket-date-paragraph">
                              Saturday, 04.03.2023
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pay-ticket-td"
                      data-title="selected numbers"
                    >
                      <div className="pay-ticket-selected-nums">
                        <span>1</span>
                        <span>2</span>
                        <span>6</span>
                        <span>9</span>
                        <span>23</span>
                        <span>23</span>
                      </div>
                      <div className="pay-ticket-info-action">
                        <span className="pay-ticket-price">aed 10</span>
                        <button
                          type="button"
                          className="pay-ticket-remove icon-close-n"
                        />
                        <button
                          type="button"
                          className="pay-ticket-remove icon-edit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ticket-draw-txt">
        <span>
          Your purchase of 1 ticket you eligible to enter the Raffle Draw.
          Choose 6 numbers in each line to participate.
        </span>
      </div>
      <div className="shopping-cart-btn-wrp">
        <p className="pay-footer-price-text">
          <span>Price:&nbsp;</span>AED 30
        </p>
        <button type="button" className="pay-footer-next-btn">
          Make Payment
        </button>
      </div>
    </div>
  );
};

const CartHeaderRow: React.FC<EmptyProps> = () => {
  return (
    <div className="shopping-cart-header-row">
      <div className="shopping-cart-header-row-clm type-1" />
      <div className="shopping-cart-header-row-clm">
        <p className="sh-c-h-txt">Raffle ID</p>
      </div>
      <div className="shopping-cart-header-row-clm">
        <p className="sh-c-h-txt">Status</p>
      </div>
      <div className="shopping-cart-header-row-clm">
        <p className="sh-c-h-txt">Number of Tickets</p>
      </div>
      <div className="shopping-cart-header-row-clm">
        <p className="sh-c-h-txt">Price</p>
      </div>
      <div className="shopping-cart-header-row-clm">
        <p className="sh-c-h-txt">Raffle Date</p>
      </div>
      <div className="shopping-cart-header-row-clm type-1" />
    </div>
  );
};

export default RecurringPurchases;
