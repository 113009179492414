import {useEffect, useState} from "react";

export default function useValidationError(){
    const [message, setMessage] = useState('')
    useEffect(()=>{
        let t = 0;
        if(message){
            t = window.setTimeout(()=>{
                setMessage('')
            },2000)
        }
        return ()=>{
            clearTimeout(t)
        }
    },[message])
    return {
        message,
        setMessage
    }
}