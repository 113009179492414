import {Outlet, useLocation} from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, {useState} from "react";
import ToastContainer from "../components/ToastContainer";

type Props = {};

const Layout: React.FC<Props> = () => {
    let location = useLocation();

    return (
        <div className='main-wrapper'>
            <Header/>
            <div className="content">
                <Outlet/>
            </div>
            {!(location.pathname === '/signup' || location.pathname === '/login') &&
                <Footer/>
            }
            <ToastContainer/>
        </div>
    );
};

export default Layout;
