import {Appearance, StripeElementsOptions} from "@stripe/stripe-js";


import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState} from "react";
import CheckoutForm from "./CheckoutForm";
import ErrorBoundary from "./ErrorBoundary";

// const stripePromise = loadStripe("pk_live_51MQnsXCx1nXuEVD79VXgduOsA5SJ2yCi5GmdroTARamBLIExFYEgmZAZ2BzLfpZfJVghdKOl6Ae6yCsdMmd9zWMC00R0EWyMRj");


const appearance = {
    theme: "stripe",
} as Appearance;
const StripeForm:React.FC<{clientSecret:string, raffleId:number, loadStateChanged:(value:string)=>void, onSuccess:()=>void}> = ({clientSecret,onSuccess,raffleId, loadStateChanged})=>{




    const stripePromise = useMemo(()=>{
        if(!clientSecret)
            return null;
        return loadStripe( "pk_live_51MQnsXCx1nXuEVD79VXgduOsA5SJ2yCi5GmdroTARamBLIExFYEgmZAZ2BzLfpZfJVghdKOl6Ae6yCsdMmd9zWMC00R0EWyMRj")
            .catch(e=>{
                alert(e.message)
                return null
            });


    },[])
    if(!clientSecret)
        return null;

    function handleLoadSuccess(){
        loadStateChanged('success')
    }
    function handleLoadError(){
        loadStateChanged('error')
    }

    const options = {
        clientSecret:clientSecret,
        appearance,

    } as StripeElementsOptions;
    return (
            <ErrorBoundary>
                <Elements key={clientSecret} options={options} stripe={stripePromise}>
                      <CheckoutForm
                            raffleId={raffleId}
                            onLoadSuccess={handleLoadSuccess}
                            onLoadError={handleLoadError}
                            onSuccess={onSuccess}
                        />

                </Elements>
            </ErrorBoundary>
    )
}


export default StripeForm