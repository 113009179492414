import React from "react";
import { UserRedirect } from "types";
import { Navigate, Outlet } from "react-router-dom";

const AuthorisedRoutes: React.FC<UserRedirect> = ({
  user,
  redirectPath = "/landing",
}) => {
  if (!user?.id) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AuthorisedRoutes;
