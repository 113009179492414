import visa from "../assets/images/visa.png";
import masterclass from "../assets/images/mastercard.png";
import useBasket from "../hooks/useBasket";
import React, { useEffect, useState } from "react";
import { RaffleTicketRowProps } from "types";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  selectRaffleData,
  selectRaffles,
} from "../features/raffles/rafflesSlice";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import CurrentRaffleTopBanner from "../components/CurrentRaffleTopBanner";
import {
  addTicketAsync,
  checkTransactionAsync,
  clearBasketError,
  clearRaffleBasket,
  clearTicketByNumber,
  stripePaymentSuccess,
} from "../features/basket/basketSlice";
import Popup from "../components/Popup";
import {
  getUserAsync,
  selectTerminalMode,
  selectUser,
} from "../features/user/userSlice";
import dayjs from "dayjs";
import Loader from "../components/Loader";

import StripeForm from "../components/StripeForm";
import { addTickets, getStripeKey } from "../api/rafflesApi";
import AmountView from "../components/common/AmountView";
import { useTranslation } from "react-i18next";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import { showToast } from "../components/ToastContainer";

type Props = {};

const Pay: React.FC<Props> = () => {
  const { raffleTickets, raffleId, raffleData } = useBasket();
  const raffle = useAppSelector((state) => selectRaffleData(state, raffleId));
  const user = useAppSelector(selectUser);

  const [paySource, setPaySource] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [stripeLoadState, setStripeLoadState] = useState("idle");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [requesting, setRequesting] = useState(false);
  const terminalEnabled = useAppSelector(selectTerminalMode);

  useEffect(() => {
    console.log("stripeLoadState", stripeLoadState);
    if (stripeLoadState === "error") {
      console.log(9999, stripeLoadState);
      setClientSecret(() => "");
      setPaySource(() => 0);
    }
  }, [stripeLoadState]);
  async function handleMakePaymentClick() {
    if (!user?.id) {
      return navigate("/login", {
        state: {
          redirectTo: "/raffles/" + raffleId + "/tickets",
        },
      });
    }
    setRequesting(true);
    await dispatch(
      addTicketAsync({
        raffleId,
        paySource,
        promoCode,
        tickets: raffleTickets.tickets.map((t, idx) => {
          return {
            numbers: t.numbers,
            nftId: raffleTickets.nfts[idx].id,
          };
        }),
      })
    );
    setRequesting(false);
    dispatch(getUserAsync());
  }

  useEffect(() => {
    if (raffleTickets.status === "paid") {
      navigate(
        "/raffles/" +
          raffleId +
          "/make-payment?payment=success&trx=" +
          raffleTickets.pendingTrxId,
        {
          replace: true,
        }
      );
    }
  }, [raffleTickets.status, raffleTickets.pendingTrxId]);
  function handleError() {
    // dispatch(clearRaffleBasket({raffleId}))
    //
    // navigate('/home',{
    //     replace:true
    // })
    dispatch(clearBasketError({ raffleId }));
  }
  function handleSuccess() {
    dispatch(clearRaffleBasket({ raffleId }));

    navigate("/raffles/" + raffleId + "/info", {
      replace: true,
    });
  }
  function handleCloseWaitingPopup() {
    dispatch(clearRaffleBasket({ raffleId }));
    navigate("/home", {
      replace: true,
    });
  }
  function handleStripePaymentSuccess() {
    dispatch(stripePaymentSuccess({ raffleId }));
  }
  useEffect(() => {
    let t = 0;
    if (raffleTickets.status === "pending-payment") {
      t = window.setInterval(() => {
        dispatch(checkTransactionAsync(raffleId));
      }, 3000);
    }
    return () => {
      clearInterval(t);
    };
  }, [raffleTickets.status, raffleId]);

  useEffect(() => {
    if (!user?.id) {
      return navigate("/login", {
        state: {
          redirectTo: "/raffles/" + raffleId + "/tickets",
        },
      });
    }
    if (paySource === 2) {
      //stripe login
      addTickets({
        raffleId,
        paySource,
        promoCode,
        tickets: raffleTickets.tickets.map((t, idx) => {
          return {
            numbers: t.numbers,
            nftId: raffleTickets.nfts[idx].id,
          };
        }),
      })
        .then((a) => {
          console.log(a);
          setClientSecret(a.payment.clientSecret);
        })
        .catch((e) => {
          showToast("warning", e.message);
          setPaySource(0);
        });
    } else {
      setClientSecret("");
    }
  }, [paySource, raffleTickets.tickets]);

  if (!raffle || !raffleData) {
    return <Navigate to="/home" />;
  } else if (raffleTickets.tickets.length !== raffleTickets.nfts.length) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="pay-page-wrapper">
      <CurrentRaffleTopBanner data={raffleData} />
      {raffleTickets.status === "paid" ? (
        <Popup
          headerText={"Congratulations"}
          contentText={t("ticket-placed-success", {
            value: dayjs(raffleData.startDate * 1000).format(
              "DD.MM.YYYY HH:mm"
            ),
          })}
        >
          <button onClick={handleSuccess} className="popup-button fill">
            {t("Raffle Details")}
          </button>
        </Popup>
      ) : raffleTickets.status === "error" ? (
        <Popup
          headerText={t("Payment Error")}
          contentText={t(raffleTickets.errorMessage || "failed to proceed")}
        >
          <button onClick={handleError} className="popup-button fill">
            {t("Close")}
          </button>
        </Popup>
      ) : raffleTickets.status === "pending-payment" ? (
        <Popup
          onClose={handleCloseWaitingPopup}
          headerText={t("Waiting for payment")}
          contentText={t("Please make payment using POS terminal")}
        >
          <Loader />
        </Popup>
      ) : null}
      <div className="pay-and-join-block">
        <div className="container">
          <h2 className="pay-and-join-title">{t("Pay and join the raffle")}</h2>
          <div className="pay-ticket-table">
            <div className="pay-ticket-thead">
              <div className="pay-ticket-th">{t("ticket details")}</div>
              <div className="pay-ticket-th">{t("selected numbers")}</div>
            </div>
            <div className="pay-ticket-tbody">
              {raffleTickets.tickets.map((rt, idx) => (
                <RaffleTicketRow
                  raffle={raffle}
                  ticket={rt}
                  number={idx + 1}
                  nft={raffleTickets.nfts[idx]}
                  key={idx}
                />
              ))}
            </div>
          </div>
          <div className="pay-page-footer">
            <div className="pay-footer-info-col">
              <p className="txt-payment-methods">{t("payment methods")}</p>
              <div className="radio-block-wrp" onClick={() => setPaySource(0)}>
                <div className="radio-block-left">
                  <div
                    className={classNames("r-circle-wrp", {
                      active: paySource === 0,
                    })}
                  >
                    <div className="r-circle-holder" />
                  </div>
                  <div className="radio-block-txt-wrp">
                    <p className="txt-available-balance">
                      {t("Available Balance")}:
                    </p>
                    <p className="your-balance">
                      {t("Your Balance")}:{" "}
                      <span>
                        {" "}
                        <AmountView value={user?.balance || 0} />{" "}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {terminalEnabled ? (
                <div
                  className="radio-block-wrp"
                  onClick={() => setPaySource(1)}
                >
                  <div className="radio-block-left">
                    <div
                      className={classNames("r-circle-wrp", {
                        active: paySource === 1,
                      })}
                    >
                      <div className="r-circle-holder" />
                    </div>
                    <div className="radio-block-txt-wrp">
                      <p className="txt-available-balance">
                        {t("Credit/Debit Cards")}
                      </p>
                      <p className="your-balance">
                        {t("Pay with your Credit / Debit Card")}
                      </p>
                    </div>
                  </div>
                  <div className="radio-block-right">
                    <div className="visa-card-img-block active">
                      <img src={visa} alt="" />
                    </div>
                    <div className="visa-card-img-block">
                      <img src={masterclass} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="radio-block-wrp"
                  onClick={() => setPaySource(2)}
                >
                  <div className="radio-block-left">
                    <div
                      className={classNames("r-circle-wrp", {
                        active: paySource === 2,
                      })}
                    >
                      <div className="r-circle-holder" />
                    </div>
                    <div className="radio-block-txt-wrp">
                      <p className="txt-available-balance">
                        {t("Credit/Debit Cards")}
                      </p>
                      <p className="your-balance">
                        {t("Pay with your Credit / Debit Card")}
                      </p>
                    </div>
                  </div>
                  <div className="radio-block-right">
                    <div className="visa-card-img-block active">
                      <img src={visa} alt="" />
                    </div>
                    <div className="visa-card-img-block">
                      <img src={masterclass} alt="" />
                    </div>
                  </div>
                </div>
              )}

              {paySource === 2 && clientSecret ? (
                <StripeForm
                  clientSecret={clientSecret}
                  raffleId={raffleId}
                  loadStateChanged={setStripeLoadState}
                  onSuccess={handleStripePaymentSuccess}
                />
              ) : null}
              <div className="radio-block-wrp" onClick={() => setPaySource(3)}>
                <div className="radio-block-left">
                  <div
                    className={classNames("r-circle-wrp", {
                      active: paySource === 3,
                    })}
                  >
                    <div className="r-circle-holder" />
                  </div>
                  <div className="radio-block-txt-wrp">
                    <p className="txt-available-balance">
                      {t("Pay with coupon")}:
                    </p>
                    {/*<p className="your-balance">{t('Your Balance')}: <span> <AmountView value={user?.balance || 0}/> </span></p>*/}
                  </div>
                </div>
              </div>

              {paySource === 3 ? (
                <div className="payment-input-wrp">
                  <div className="payment-input-row">
                    <div className="payment-input-clm type1">
                      <input
                        className="input-payment"
                        type="text"
                        placeholder="Code"
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="pay-footer-total">
              <p className="pay-footer-payment-text">
                {t("payment-footer-text")}
              </p>
              <p className="pay-footer-price-text">
                <span>Price: </span>
                <AmountView
                  value={raffle.ticketPrice * raffleTickets.tickets.length}
                  shouldConvert={false}
                />
                {/*{raffle.currency} {raffle.ticketPrice * raffleTickets.tickets.length}*/}
              </p>
              {paySource !== 2 ? (
                <button
                  type="button"
                  className={classNames("pay-footer-next-btn", {
                    disabled: requesting,
                  })}
                  onClick={handleMakePaymentClick}
                >
                  {!requesting ? t("Make Payment") : t("Processing")}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RaffleTicketRow: React.FC<RaffleTicketRowProps> = ({
  ticket,
  raffle,
  nft,
  number,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  function handleRemoveTicket() {
    dispatch(
      clearTicketByNumber({
        raffleId: raffle?.id || 0,
        ticketNumber: number,
      })
    );
  }

  return (
    <div className="pay-ticket-tr">
      <div className="pay-ticket-td" data-title="ticket details">
        <div className="pay-ticket-details">
          <div className="pay-ticket-image">
            <img src={nft.media.small} alt="" />
          </div>
          <div className="pay-ticket-info">
            <h4 className="pay-ticket-title">{t(nft.name)}</h4>
            <div className="pay-ticket-date">
              <h5 className="pay-ticket-date-title">{t(raffle?.name || "")}</h5>
              <p className="pay-ticket-date-paragraph">
                {dayjs((raffle?.startDate || 0) * 1000).format("DD.MM.YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pay-ticket-td" data-title={t("selected numbers")}>
        <div className="pay-ticket-selected-nums">
          {ticket.numbers.map((n) => (
            <span key={n}>{n}</span>
          ))}
        </div>
        <div className="pay-ticket-info-action">
          <span className="pay-ticket-price">
            <AmountView value={raffle?.ticketPrice || 0} />
          </span>
          <button
            type="button"
            className="pay-ticket-remove icon-close-n"
            onClick={handleRemoveTicket}
          />
        </div>
      </div>
    </div>
  );
};

export default Pay;
