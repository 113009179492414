import React from "react";

type Props = {};

const Messages: React.FC<Props> = () => {
    return (
        <div className="messages-wrapper">
            <div className="b-s-header">
                <p className="b-s-header-txt">
                   Messages
                </p>
            </div>
            <div className="message-table">
                <div className="message-table-inner">
                    <div className="message-table-rows header-message">
                        <p className="message-table-rows-columns type1">
                            Subjekt
                        </p>
                        <p className="message-table-rows-columns type2">
                            Date
                        </p>
                        <p className="message-table-rows-columns type3">
                           Message ID
                        </p>
                        <p className="message-table-rows-columns type2">
                           Language
                        </p>
                        <p className="message-table-rows-columns type4">
                            Email address
                        </p>
                    </div>
                    <div className="message-table-rows col">
                        <p className="message-table-rows-columns pink type1">
                            This is subject and it’s really long
                        </p>
                        <p className="message-table-rows-columns type2">
                            02.06.2023
                        </p>
                        <p className="message-table-rows-columns type3">
                            #12631231387
                        </p>
                        <p className="message-table-rows-columns type2">
                            EN
                        </p>
                        <p className="message-table-rows-columns type4">
                            Youremailisrealyyyyylong@gmail.com
                        </p>
                    </div>
                    <div className="message-table-rows ">
                        <p className="message-table-rows-columns pink type1">
                            This is subject and it’s really long
                        </p>
                        <p className="message-table-rows-columns type2">
                            02.06.2023
                        </p>
                        <p className="message-table-rows-columns type3">
                            #12631231387
                        </p>
                        <p className="message-table-rows-columns type2">
                            EN
                        </p>
                        <p className="message-table-rows-columns type4">
                            Youremailisrealyyyyylong@gmail.com
                        </p>
                    </div>
                    <div className="message-table-rows col">
                        <p className="message-table-rows-columns pink type1">
                            This is subject and it’s really long
                        </p>
                        <p className="message-table-rows-columns type2">
                            02.06.2023
                        </p>
                        <p className="message-table-rows-columns type3">
                            #12631231387
                        </p>
                        <p className="message-table-rows-columns type2">
                            EN
                        </p>
                        <p className="message-table-rows-columns type4">
                            Youremailisrealyyyyylong@gmail.com
                        </p>
                    </div>
                    <div className="message-table-rows ">
                        <p className="message-table-rows-columns pink type1">
                            This is subject and it’s really long
                        </p>
                        <p className="message-table-rows-columns type2">
                            02.06.2023
                        </p>
                        <p className="message-table-rows-columns type3">
                            #12631231387
                        </p>
                        <p className="message-table-rows-columns type2">
                            EN
                        </p>
                        <p className="message-table-rows-columns type4">
                            Youremailisrealyyyyylong@gmail.com
                        </p>
                    </div>

                </div>
            </div>
            <div className="pagination-wrapper">
                <div className="pagination-txt-block">
                    <p className="pagination-txt-page">Items per page</p>
                    <div className="pagination-arrow-block"> {/*add/remove class open*/}
                        <span className="pagination-arrow-count">1</span>
                        <i className="icon-arrow-top"/>
                        <div className="pagination-count-open-block">
                            <span>1</span>
                            <span>1</span>
                            <span>1</span>
                            <span>1</span>
                        </div>
                    </div>
                </div>
                <p className="pagination-count-block">1-20</p>
                <div className="pagination-icon-block">
                    <i className="icon-arrow-left"/>
                    <i className="icon-arrow-right"/>
                </div>
            </div>
        </div>
    );
};

export default Messages;
