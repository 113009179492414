import React, {PropsWithChildren} from "react";
import {useAppSelector} from "../../app/hooks";
import {selectTerminalMode} from "../../features/user/userSlice";

const OnlyForWeb:React.FC<PropsWithChildren> = ({children})=>{
    const isTerminal = useAppSelector(selectTerminalMode)

    if(isTerminal)
        return null
    return <>
        {children}
    </>
}
export default OnlyForWeb