import axios from "axios";
import {
  AddRaffleData,
  RaffleInfoData,
  ResendOtpData,
  User,
  UserLoginData,
  VerifyOtpData,
} from "types";
import { PayOutFormValues } from "../components/WithdrawAssets";
import { DepositFormValues } from "../components/DepositForm";

const API_URL = process.env.REACT_APP_API_PANEL_PATH;
const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export async function getRaffles() {
  return makePostRequest("/raffle");
}

export async function joinRaffle(id: number) {
  const { data } = await instance.get("join/" + id);
  return data;
}
export async function login(userLoginData: UserLoginData) {
  return makePostRequest("users/login", userLoginData);
}
export async function getUser() {
  return makePostRequest("users/getUser");
}
export async function verifyOtp(
  verifyOtpData: VerifyOtpData & { terminalId: string | null }
) {
  return makePostRequest("users/verifyOtp", verifyOtpData);
}
export async function resendOtp(verifyOtpData: ResendOtpData) {
  return makePostRequest("users/resendOtp", verifyOtpData);
}
export async function logout() {
  return makePostRequest("users/logout");
}
export async function raffleInfo(raffleInfoData: RaffleInfoData) {
  return makePostRequest("/raffle/raffleInfo", raffleInfoData);
}
export async function getRaffleNFTs(
  raffleId: number,
  page = 0,
  orderBy = "asc"
) {
  return makePostRequest("/raffle/nftsForRaffle", {
    raffleId,
    page,
    orderBy,
  });
}
export async function getMyTransactions(page = 0, limit = 5) {
  return makePostRequest("/users/myTransactions", { page, limit });
}
export async function getMyTickets(page = 0, limit = 5) {
  return makePostRequest("/users/myTickets", { page, limit });
}
export async function updateUserProfile(user: Partial<User>) {
  return makePostRequest("/users/updateProfile", user);
}
export async function makePyout(user: PayOutFormValues) {
  return makePostRequest("/users/payout", user);
}
export async function makeDeposit(user: DepositFormValues) {
  return makePostRequest("/users/deposit", user);
}
export async function getStripeKey(ticketsCount: number) {
  return axios.post(API_URL + "/payment/createStripeOrder", { ticketsCount });
}

export async function addTickets(addRaffleData: AddRaffleData) {
  const data = await makePostRequest("/raffle/addTickets", addRaffleData);
  if (data.payment?.payStatus === 0) {
    throw new Error(
      data.payment?.message || "Failed to process. Try again later."
    );
  }
  return data;
}
export async function checkTransaction(trxId: number) {
  const { data } = await instance.post("/payment/checkPaymentStatus", {
    trxId,
  });
  if (data.code) {
    if (data.code !== 7) {
      throw new Error(data.message);
    }
  }

  return data;
}

async function makePostRequest(path: string, requestData: any = {}) {
  const { data } = await instance.post(path, requestData);
  if (data.code) {
    if (data.code) throw new Error(data.message);
  }

  return data.data;
}
