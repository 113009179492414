import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
// import logo from "./logo.svg";
// import { Counter } from "./features/counter/Counter";

import "./assets/styles/index.scss";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Tickets from "./pages/Tickets";
import Collections from "./pages/Collections";
import Buy from "./pages/Buy";
import Pay from "./pages/Pay";
import RaffleHome from "./pages/RaffleHome";
import Balance from "./pages/Balance";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Layout from "./pages/Layout";
import UserLayout from "./pages/userPages/UserLayout";
import MyProfile from "./pages/userPages/MyProfile";
import RecurringPurchases from "./pages/userPages/RecurringPurchases";
import TicketHistory from "./pages/userPages/TicketHistory";
import Messages from "./pages/userPages/Messages";
import MyGame from "./pages/userPages/MyGame";
import BalanceSummary from "./pages/userPages/BalanceSummary";
import FavoriteNumbers from "./pages/userPages/FavoriteNumbers";
import MyCreditCard from "./pages/userPages/MyCreditCard";
import ScrollToTop from "./ScrollToTop";
import SignUp from "./pages/Signup";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {getRafflesASync, selectRaffles, selectRafflesStatus} from "./features/raffles/rafflesSlice";
import {useEffect, useState} from "react";
import {getUserAsync, selectUser} from "./features/user/userSlice";
import UnAuthorisedRoutes from "./components/Routes/UnAuthorisedRoutes";
import AuthorisedRoutes from "./components/Routes/AuthorisedRoutes";
import SplashScreen from "./components/SplashScreen";
import RaffleBucket from "./components/Routes/RaffleBacket";
import TermsAndConditions from "./pages/TermsAndConditions";
import PayOut from "./pages/userPages/PayOut";
import FairnessCheck from "./pages/FairnessCheck";
import WithRaffleInfo from "./components/Routes/WithRaffleInfo";
import Deposit from "./pages/userPages/Deposit";

function App() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{


        setInterval(()=>{
            dispatch(getRafflesASync())
        },60*1000)
        async function init(){
            return Promise.all([dispatch(getRafflesASync()),
            dispatch(getUserAsync())  ])
        }
        init().then(()=>{
            setLoaded(true)
        })
    },[dispatch])
    useEffect(()=>{
        if(user?.id){
            getRafflesASync()
        }
    },[user?.id])

    const raffles = useAppSelector(selectRaffles)

    if(!raffles.length || !loaded)
        return <SplashScreen/>
    // return  <Counter/>
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index element={<Home/>}/>

                    {/*raffle action routes*/}


                    <Route path="/tickets" element={<Tickets/>}/>

                    <Route element={<WithRaffleInfo/>}>
                        <Route path="/raffles/:raffleId/info" element={<RaffleHome/>}/>
                        <Route path="/raffles/:raffleId/fairness" element={<FairnessCheck/>}/>
                    </Route>
                    <Route element={<RaffleBucket
                    />}>
                        <Route path="/raffles/:raffleId/tickets" element={<Tickets/>}/>
                        <Route path="/raffles/:raffleId/collections" element={<Collections/>}/>
                        <Route path="/raffles/:raffleId/make-payment" element={<Pay/>}/>

                    </Route>
                    <Route element={<UnAuthorisedRoutes
                        user={user}
                        redirectPath='/'
                    />}>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/signup" element={<Login/>}/>
                        {/*<Route path="/signup" element={<SignUp />}/>*/}
                    </Route>
                    <Route element={<AuthorisedRoutes
                        user={user}
                        redirectPath='/'
                    />}>
                        {/*<Route path="/balance" element={<Balance/>}/>*/}
                        <Route path="/user-page" element={<UserLayout/>}>
                            <Route path="/user-page/profile" element={<MyProfile/>}/>
                            <Route path="/user-page/payout" element={<PayOut/>}/>
                            <Route path="/user-page/deposit" element={<Deposit/>}/>
                            <Route path="/user-page/cart" element={<RecurringPurchases/>}/>
                            <Route path="/user-page/ticket_history" element={<TicketHistory/>}/>
                            <Route path="/user-page/messages" element={<Messages/>}/>
                            {/*<Route path="/user-page/my_game" element={<MyGame/>}/>*/}
                            <Route path="/user-page/ballance_summary" element={<BalanceSummary/>}/>
                            <Route path="/user-page/favorite_numbers" element={<FavoriteNumbers/>}/>
                            <Route path="/user-page/my_credit_card" element={<MyCreditCard/>}/>
                            <Route path="/user-page" element={<Navigate to="/user-page/profile"/>}/>

                        </Route>
                    </Route>

                    <Route path="/terms" element={<TermsAndConditions/>}/>
                    {/*fallback to home*/}
                    <Route path="*" element={<Home/>}/>

                </Route>
            </Routes>
        </Router>
    );
}

export default App;
