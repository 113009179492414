import React from "react";
import {EmptyProps} from "../shared/types";
import {useTranslation} from "react-i18next";

const TermsAndConditions:React.FC<EmptyProps> = ()=>{
    const {t} = useTranslation()
    return (<div className='container'>
        <div className="terms-conditions-wrp" dangerouslySetInnerHTML={{__html:t('terms-and-conditions') as string}}>
        </div>
    </div>)
}

export default TermsAndConditions