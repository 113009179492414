import { NavLink, Outlet} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";

type Props = {};

const UserLayout: React.FC<Props> = () => {
    const [isOpenUserNav, setIsOpenUserNav] = useState(false);
    const {t}= useTranslation()
    return (
        <div className="user-page-wrapper">
            <div className='user-page-inner container'>
                <div className={`user-page-nav ${isOpenUserNav ? 'active' : ''}`}>
                    <NavLink to='/user-page/profile'>{t('My Profile')}</NavLink>
                    {/*<NavLink to='/user-page/recurring_purchases'>Recurring purchases</NavLink>*/}
                    <NavLink to='/user-page/ticket_history'>{t('My Tickets')}</NavLink>
                    {/*<NavLink to='/user-page/messages'>Messages</NavLink>*/}
                    {/*<NavLink to='/user-page/my_game'>My game</NavLink>*/}
                    <NavLink to='/user-page/ballance_summary'>{t('Balance summary')}</NavLink>
                    <NavLink to='/user-page/payout'>{t('Withdraw')}</NavLink>
                    <NavLink to='/user-page/deposit'>{t('Deposit')}</NavLink>
                    {/*<NavLink to='/user-page/favorite_numbers'>Favorite numbers</NavLink>*/}
                    {/*<NavLink to='/user-page/cart'>Cart</NavLink>*/}
                </div>
                {/*<button type='button' onClick={() => setIsOpenUserNav((current) => !current)} className='user-page-burger icon-burger' />*/}
                <div className="user-page-body">
                    <Outlet />
                </div>
            </div>


        </div>
    );
};

export default UserLayout;
