import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getRafflesASync,
  selectUpcomingRaffles,
  selectRaffles,
} from "../../features/raffles/rafflesSlice";
import { RaffleButtonProps, RaffleProps } from "types";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CountdownTimeDelta } from "react-countdown/dist/utils";
import AmountView, { formatValue } from "../common/AmountView";
import { useTranslation } from "react-i18next";
import {
  selectUserCurrency,
  selectUserCurrencyTemplate,
} from "../../features/user/userSlice";

type Props = {
  startTime?: number;
  type?: string;
  className?: string;
  onComplete?: (
    timeDelta: CountdownTimeDelta,
    completedOnStart: boolean
  ) => void;
};

const UpcomingRaffles: React.FC<Props> = () => {
  const [entireRafflesData, setEntireRafflesData] = useState<any>([]);
  const raffles = useSelector(selectUpcomingRaffles);
  const entireRaffles = useSelector(selectRaffles);

  let navigate = useNavigate();
  const currency = useAppSelector(selectUserCurrency);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setEntireRafflesData(entireRaffles);
  }, [entireRaffles]);

  function handleOnComplete() {
    console.log("completed");
    dispatch(getRafflesASync());
  }

  function handleJoinButtonClick(id: number) {
    navigate("/raffles/" + id + "/tickets");
  }

  console.log("rafflessss", raffles);

  if (!raffles.length) return null;
  // <div className="chance-row container">
  //     <h3 className="chance-row-title">{t('Get the chance to win')}</h3>
  //     <p className="chance-row-sub-title">{t('Buy ftNFT to enter the draws!')}</p>
  //     <p className="chance-row-sub-title">{t('No draws available')}</p>
  // </div>;
  return (
    <>
      <div className="chance-row container top-banner">
        <div className="home-banners-container">
          <div className="home-top-big-column" style={{ margin: "initial" }}>
            <h3 className="chance-row-title">
              {t("First Blockchain Raffle on the First Emirati Blockchain")}
            </h3>
            <h3 className="chance-money">
              {t("Join for only")}{" "}
              <AmountView value={raffles[0]?.ticketPrice} />
            </h3>
            {/*<p className="chance-row-title">{t('hourly prizes', {value:(raffles[0].prizeFund) + ' + ' + currency })}</p>*/}
            {/*<p className="chance-row-sub-title">{t('hourly prizes', {value:(raffles[0].prizeFund) + '+ ' + currency })}</p>*/}
            <MajorRaffle
              key={raffles[0].id}
              data={raffles[0]}
              onComplete={handleOnComplete}
            />
            <div
              className="big-banner-switcher-container"
              style={{ display: "none" }}
            >
              <button className="switcher-ellipses-btn" />
              <button className="switcher-ellipses-btn" />
              <button className="switcher-ellipses-btn" />
              <button className="switcher-ellipses-btn" />
            </div>
          </div>
          <div className="home-top-small-column" style={{ display: "none" }}>
            <p className="top-small-column-title-txt">Next Raffle</p>
            <button
              className="btn-bottom type-2"
              onClick={() => handleJoinButtonClick(entireRaffles[0].id)}
            >
              Join Now
            </button>
            {/* <JoinRaffleButton data={raffles[1]} className={"chance-button"} /> */}
            <div className="top-small-column-table-container">
              <div className="top-small-table-row head">
                <span className="top-column-table-item">Winner</span>
                <span className="top-column-table-item">Ticket ID</span>
                <span className="top-column-table-item">Raffle ID</span>
                <span className="top-column-table-item">Amount</span>
              </div>
              <div className="top-small-table-content">
                {entireRaffles.slice(0, 4).map((raffle) => (
                  <div className="top-small-table-row">
                    <span className="top-column-table-item">User</span>
                    <span className="top-column-table-item">453413</span>
                    <span className="top-column-table-item">45123212</span>
                    <span className="top-column-table-item">500 AED</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/*<div className="chance-cards-holder">*/}
          {/*    <div className="chance-card-column">*/}
          {/*        <MinorRaffle data={raffles[1]} type="super"/>*/}
          {/*        {raffles[3] ? <MiniRaffle data={raffles[3]}/> : null}*/}
          {/*    </div>*/}
          {/*    <div className="chance-card-column">*/}
          {/*        <MinorRaffle data={raffles[2]} type="fantastic"/>*/}
          {/*        {raffles[4] ? <MiniRaffle data={raffles[4]}/> : null}*/}

          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </div>
      {/* {raffles[1] ? (
        <UpcomingRaffle key={raffles[1].id} data={raffles[1]} />
      ) : null} */}
    </>
  );
};

const UpcomingRaffle: React.FC<RaffleProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="banner-block home-banner">
      <div className="banner-block-inner container">
        <div className="banner-body">
          <span className="icon-FtNft buy-nft-section-top-icon" />
          <span className="buy-nft-middle-section-title">
            {t("Upcoming Raffle")}
          </span>
          {/*<span className="buy-nft-middle-section-subtitle">{t('Join Raffle')}</span>*/}
          {/*<span className="buy-nft-middle-big-txt"><AmountView value={data.prizeFund}/></span>*/}
          <div className="buy-nft-section-timer-container">
            <span className="buy-nft-section-days-txt">
              {dayjs(data.startDate * 1000).format("DD MMM, YYYY")}
            </span>
            <span className="buy-nft-section-hours-txt">
              {dayjs(data.startDate * 1000).format("HH:mm")}
            </span>
          </div>
          <JoinRaffleButton data={data} className={"chance-button"} />
        </div>
      </div>
    </div>
  );
};

const MajorRenderer: React.FC<CountdownRenderProps & { type?: string }> = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  type,
}) => {
  return (
    <div className={classNames("chance-count-down", type)}>
      <div
        className="chance-count-down-column"
        data-web-label="days"
        data-mob-label="days"
      >
        <p className="chance-count-down-num">{days}</p>
      </div>
      <div className="chance-count-down-doth">:</div>
      <div
        className="chance-count-down-column"
        data-web-label="hours"
        data-mob-label="hou."
      >
        <p className="chance-count-down-num">{hours}</p>
      </div>
      <div className="chance-count-down-doth">:</div>
      <div
        className="chance-count-down-column"
        data-web-label="minutes"
        data-mob-label="min."
      >
        <p className="chance-count-down-num">{minutes}</p>
      </div>
      <div className="chance-count-down-doth">:</div>
      <div
        className="chance-count-down-column"
        data-web-label="seconds"
        data-mob-label="sec."
      >
        <p className="chance-count-down-num">{seconds}</p>
      </div>
    </div>
  );
};

const MajorRaffle: React.FC<RaffleProps> = ({ data }) => {
  const countDownTime = useMemo(() => {
    return data.startDate * 1000;
  }, [data.startDate]);
  const { t } = useTranslation();
  const currency = useAppSelector(selectUserCurrency);
  return (
    <div className="chance-count-row">
      {/*<RaffleCountDownTimer type="big" startTime={countDownTime}/>*/}
      <p className="chance-row-title bottom">
        {t("hourly prizes", { value: data.prizeFund ? data.prizeFund + "+ " + currency : '' })}
      </p>

      {/*<h3 className="chance-money">Join for only <AmountView value={data.ticketPrice}/></h3>*/}
      <JoinRaffleButton data={data} className={"chance-button"} />
    </div>
  );
};

export const RaffleCountDownTimer: React.FC<Props> = ({
  startTime,
  type,
  onComplete,
}) => {
  return (
    <Countdown
      date={startTime}
      renderer={(props) => <MajorRenderer {...props} type={type} />}
      autoStart={true}
      onComplete={onComplete}
    />
  );
};
const MinorRaffle: React.FC<RaffleProps> = ({ data, type }) => {
  const showBigDraw = useMemo(() => {
    return data.prizeFund - data.guaranteedPrize > 0;
  }, [data]);
  const { t } = useTranslation();
  return (
    <div className={classNames("chance-card", type)}>
      <div className="chance-card-head">
        <p className="chance-card-name">{data.name}</p>
        <span className="chance-card-date">
          {dayjs(data.startDate * 1000).format("DD.MM.YYYY")}
        </span>
      </div>
      <div
        className={classNames("chance-card-money-row", {
          "align-items": showBigDraw,
        })}
      >
        <div className="chance-card-money-column">
          <span className="chance-card-money-name">{t("raffle drew")}</span>
          <p className="chance-card-money">
            <AmountView value={data.prizeFund} />
          </p>
        </div>
        {showBigDraw ? (
          <>
            <div className="chance-card-money-plus">+</div>
            <div className="chance-card-money-column">
              <span className="chance-card-money-name">{t("big drew")}</span>
              <p className="chance-card-money">
                <AmountView value={data.prizeFund - data.guaranteedPrize} />
              </p>
            </div>
          </>
        ) : null}
      </div>
      <RaffleCountDownTimer type="circle" startTime={data.startDate * 1000} />
      <JoinRaffleButton data={data} className={"chance-card-button"} />
    </div>
  );
};

const MiniRaffle: React.FC<RaffleProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="chance-card-info">
      <div className="chance-card-info-head">
        <p className="chance-card-info-name">{data.name}</p>
        <span className="chance-card-info-date">
          {dayjs(data.startDate * 1000).format("DD.MM.YYYY")}
        </span>
      </div>
      <RaffleCountDownTimer type="small" startTime={data.startDate * 1000} />
      <div className="chance-card-info-money-row">
        <span className="chance-card-info-money-title">{t("raffle drew")}</span>
        <p className="chance-card-info-money">
          <AmountView value={data.prizeFund} />
        </p>
      </div>
    </div>
  );
};

export const JoinRaffleButton: React.FC<RaffleButtonProps> = ({
  data,
  className,
}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  function handleButtonClick() {
    navigate("/raffles/" + data.id + "/tickets");
  }
  const currencyTemplate = useAppSelector(selectUserCurrencyTemplate);
  return (
    <>
      <div className="banner-bot-buttons-container">
        <button className={className} onClick={handleButtonClick}>
          {data.joined
            ? t("add new tickets")
            : t("join raffle", {
                value: formatValue(data.ticketPrice, currencyTemplate),
              })}
        </button>
        <NavLink to={`/raffles/${data.id}/info`} className={"results-btn"}>
          {t("details")}
        </NavLink>
      </div>
    </>
  );
};
export default UpcomingRaffles;
