import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import rafflesReducer from '../features/raffles/rafflesSlice';
import userReducer from "../features/user/userSlice";
import basketSlice from "../features/basket/basketSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    root: rafflesReducer,
    user:userReducer,
    basket:basketSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
