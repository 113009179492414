import externalApiDev from "./external.development";
import externalApiProd from "./external.production";

const externalApi =
  process.env.NODE_ENV === "development" ? externalApiDev : externalApiProd;

const onConfirmAction = function (callbackId, ...rest) {
  if (callBacks[callbackId]) {
    callBacks[callbackId].call(null, rest);
  } else {
    console.error("received callback: " + callbackId + " does not exist.");
  }
};

const callBacks = {};
let nanoId = 0;
function request(method, data) {
  ++nanoId;
  console.log("REQUEST", method, typeof externalApi[method], nanoId);

  try {
    if (data) {
      externalApi[method](nanoId, data);
    } else {
      externalApi[method](nanoId);
    }
    return new Promise((resolve) => {
      callBacks[nanoId] = resolve;
    });
  } catch (e) {
    console.error(e);
    // alert(e.message)
    return Promise.reject(e.message);
  }
}

function requestV2(method, data) {
  console.log("REQUEST", method, typeof externalApi[method], nanoId);

  try {
    if (data) {
      return externalApi[method](data);
    } else {
      return externalApi[method]();
    }
  } catch (e) {
    console.error(e);
    // alert(e.message)
    return Promise.reject(e.message);
  }
}

function requestToPayment() {}

window.confirmOnAction = onConfirmAction;

let apiMethods = {
  enableMoneyInput: () => {
    return request("enableMoneyInput");
  }, // externalApi.enableMoneyInput,
  initStartSettings: (data) => {
    return request("initStartSettings", data);
  }, // externalApi.initStartSettings,
  disableMoneyInput: () => {
    return request("disableMoneyInput");
  },
  printBase64Ticket: (data) => {
    return request("printBase64Ticket", data);
  },
  showTapPage: (value) => {
    return requestV2("showTapPage", value);
  },
  requestToPayment,
};

if (process.env.NODE_ENV === "production") {
  apiMethods = {
    enableMoneyInput: () => {
      return requestV2("enableMoneyInput");
    }, // externalApi.enableMoneyInput,
    initStartSettings: (data) => {
      return requestV2("initStartSettings", data);
    }, // externalApi.initStartSettings,
    disableMoneyInput: () => {
      return requestV2("disableMoneyInput");
    },
    showTapPage: (value) => {
      return requestV2("showTapPage", value);
    },
    openConsole: () => {
      return requestV2("openConsole");
    },
    printBase64Ticket: (data) => {
      return requestV2("printBase64Ticket", data);
    },
    requestToPayment,
  };
}

window.apiMethods = apiMethods;

export default apiMethods;
