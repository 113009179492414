import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getRaffleInfo,
  getRafflesASync,
  selectRaffleInfo,
  selectRaffles,
  selectUpcomingRaffles,
} from "../features/raffles/rafflesSlice";
import { useSelector } from "react-redux";
import CurrentRaffleTopBanner from "../components/CurrentRaffleTopBanner";
import { EmptyProps, RaffleResultTicket } from "types";
import Tickets from "./Tickets";
import classNames from "classnames";
import Loader from "../components/Loader";
import AmountView from "../components/common/AmountView";
import { useTranslation } from "react-i18next";
import { RaffleCountDownTimer } from "../components/Home/UpcomingRaffles";
import UpcomingRaffleInfo from "../components/UpcomingRaffleInfo";

type Props = {};

const RaffleHome: React.FC<Props> = () => {
  const { raffleId } = useParams();
  const raffleInfo = useAppSelector((state) =>
    selectRaffleInfo(state, raffleId || "")
  );
  const { t } = useTranslation();

  const prizeBreakDown = useMemo(() => {
    if (!raffleInfo) return [];
    let data = [] as { winNumbers: string; match: string; prize: number }[];

    Object.keys(raffleInfo.payoutScheme)
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((pd) => {
        data.push({
          match: pd,
          winNumbers: pd,
          prize: raffleInfo.payoutScheme[pd],
        });
      });
    return data;
  }, [raffleInfo]);
  if (!raffleInfo) return <Loader />;

  return (
    <div className="nr-raffle">
      {raffleInfo.status > 1 ? <UpcomingRaffleInfo /> : null}
      {raffleInfo.status !== 1 ? (
        <div className="nr-raffle-banner">
          <div className="nr-raffle-banner-inside container">
            <div className="nr-raffle-info">
              <p className="nr-raffle-info-txt">
                #{raffleInfo.id} {t(raffleInfo.name)}
              </p>
            </div>
            <p className="nr-raffle-amount">
              {raffleInfo.prizeFund ? <AmountView value={raffleInfo.prizeFund} /> : null}
            </p>
            <span className="banner-ticket-price-txt">
              {t("Ticket price")}{" "}
              <span>
                <AmountView value={raffleInfo.ticketPrice} />
              </span>
            </span>
            <div className="nr-raffle-nums-holder">
              {raffleInfo.result.map((r, idx) => (
                <div className="nr-raffle-num" key={idx}>
                  <p className="nr-raffle-num-txt">{r}</p>
                </div>
              ))}
            </div>
            {raffleInfo.blockHash ? (
              <NavLink
                to={`/raffles/${raffleInfo.id}/fairness`}
                className="banner-bottom-button"
              >
                <span className="banner-bottom-button-txt">
                  {t("Check your result")}
                </span>
              </NavLink>
            ) : null}
            {/*<div className="banner-users-cont">*/}
            {/*    <i className="icon-user" />*/}
            {/*    <span>4 participats</span>*/}
            {/*</div>*/}
          </div>
        </div>
      ) : (
        <CurrentRaffleTopBanner data={raffleInfo} />
      )}

      <div
        className="nr-raffle-content numbers-info container"
        style={{ paddingTop: "2rem" }}
      >
        <h2 className="nr-raffle-prize-breakdown-title">
          {t("Prize Breackdown")}
        </h2>

        <div className="nr-raffle-content-row nr-raffle-content-row-heading">
          <div className="nr-raffle-info-col">
            <p className="nr-raffle-info-header-title">{t("Match")}</p>
            <p className="nr-raffle-info-header-title-mobile">{t("Match")}</p>
          </div>
        </div>

        {prizeBreakDown.map((pb, idx) => (
          <div className="nr-raffle-content-row" key={pb.match}>
            <div className="nr-raffle-info-col">
              <div className="nr-raffle-participants">
                <div className="nr-raffle-participants-icon">{pb.match}</div>
                <div className="nr-raffle-participants-info">
                  {/*<p className="nr-raffle-participants-name">Me</p>*/}
                  <p className="nr-raffle-participants-id">{t("Matches")}</p>
                </div>
              </div>
            </div>
            <div className="nr-raffle-info-col">
              <p className="nr-raffle-info-header-title">
                <AmountView value={pb.prize} />
              </p>
            </div>
          </div>
        ))}
      </div>
      <TicketsList
        key={"winners"}
        raffleResult={raffleInfo.result}
        list={raffleInfo.winners}
        title={t("Winners")}
        emptyListText={"We haven't yet identified any winners"}
      />
      <TicketsList
        key={"my-tickets"}
        raffleResult={raffleInfo.result}
        list={raffleInfo.myTickets}
        title={t("My Tickets")}
        emptyListText={t("You do not participate in this raffle")}
      />
    </div>
  );
};

type WinnersListProps = {
  list: RaffleResultTicket[];
  raffleResult: number[];
  title: string;
  emptyListText: string;
};
const TicketsList: React.FC<WinnersListProps> = ({
  title,
  emptyListText,
  list,
  raffleResult,
}) => {
  const calcList = useMemo(() => {
    return list.map((l) => {
      let matches = 0;
      let numbers = l.numbers.map((num) => {
        let matched = raffleResult.includes(num);
        if (matched) matches++;
        return {
          num,
          matched,
        };
      });

      return {
        ...l,
        matches,
        numbers,
      };
    });
  }, [list, raffleResult]);
  const { t } = useTranslation();
  return (
    <div className="nr-raffle-content numbers-info container">
      <h2 className="nr-raffle-prize-breakdown-title">{title}</h2>
      {!list.length ? (
        <div className="nr-raffle-content-row">{emptyListText}</div>
      ) : (
        <div
          className="nr-raffle-content-row nr-raffle-content-row-heading"
          key="heading"
        >
          <div className="nr-raffle-info-col">
            <p className="nr-raffle-info-header-title">{title}</p>
            <p className="nr-raffle-info-header-title-mobile">{title}</p>
          </div>
          <div className="nr-raffle-info-col">
            <p className="nr-raffle-info-header-title">{t("Lucky Numbers")}</p>
          </div>
          <div className="nr-raffle-info-col">
            <p className="nr-raffle-info-header-title">{t("Win Numbers")}</p>
          </div>
          <div className="nr-raffle-info-col">
            <p className="nr-raffle-info-header-title">{t("Prize")}</p>
          </div>
        </div>
      )}
      {calcList.map((l) => (
        <div className="nr-raffle-content-row" key={l.ticketId}>
          <div className="nr-raffle-info-col">
            <div className="nr-raffle-participants">
              <div className="nr-raffle-participants-icon">H</div>
              <div className="nr-raffle-participants-info">
                {/*<p className="nr-raffle-participants-name">Me</p>*/}
                <p className="nr-raffle-participants-id">ID {l.ticketId}</p>
              </div>
            </div>
          </div>
          <div className="nr-raffle-info-col">
            <div className="nr-raffle-lucky-numbers-holder">
              <div
                className={classNames("nr-raffle-lucky-numbers", {
                  active: l.win > 0,
                })}
              >
                {l.numbers.map((t) => (
                  <div
                    key={t.num}
                    className={classNames("nr-raffle-lucky-number", {
                      active: raffleResult.includes(t.num),
                    })}
                  >
                    <p className="nr-raffle-lucky-number-txt">{t.num}</p>
                  </div>
                ))}
              </div>
              {l.status === 3 ? (
                <div className="nr-raffle-status-mobile win">
                  <p className="nr-raffle-status-mobile-txt">
                    <AmountView value={l.win} />
                  </p>
                  <div className="nr-raffle-status-divider" />
                  <p className="nr-raffle-status-mobile-txt">Win</p>
                </div>
              ) : (
                <div className="nr-raffle-status-mobile">
                  <p className="nr-raffle-status-mobile-txt">
                    {t(l.status === 4 ? "Lose" : "Pending")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="nr-raffle-info-col">
            <div className="nr-raffle-win-numbers">
              <p className="nr-raffle-win-numbers-txt">
                {l.matches} {t("match")}
              </p>
            </div>
          </div>
          {l.status === 3 ? (
            <div className="nr-raffle-info-col">
              <div className="nr-raffle-status win">
                <p className="nr-raffle-status-txt">
                  <AmountView value={l.win} />
                </p>
                <div className="nr-raffle-status-divider" />
                <p className="nr-raffle-status-txt">{t("win")}</p>
              </div>
            </div>
          ) : (
            <div className="nr-raffle-info-col">
              <div className="nr-raffle-status">
                <p className="nr-raffle-status-txt">
                  {t(l.status === 4 ? "Lose" : "Pending")}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
      {process.env.NODE_ENV === "development" ? (
        <div className="tickets-bottom-button-container">
          <button className="btn-bottom type-1">See all tickets</button>
        </div>
      ) : null}

      {/*<div className="nr-raffle-content-row">*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-participants">*/}
      {/*            <div className="nr-raffle-participants-icon">*/}
      {/*                H*/}
      {/*            </div>*/}
      {/*            <div className="nr-raffle-participants-info">*/}
      {/*                <p className="nr-raffle-participants-name">Ella</p>*/}
      {/*                <p className="nr-raffle-participants-id">ID 498593</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-lucky-numbers-holder">*/}
      {/*            <div className="nr-raffle-lucky-numbers">*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">6</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">2</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">4</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">8</p>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*            /!* nr-raffle-status-mobile is for only mobile *!/*/}
      {/*            <div className="nr-raffle-status-mobile">*/}
      {/*                <p className="nr-raffle-status-mobile-txt">Loose</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-win-numbers">*/}
      {/*            <p className="nr-raffle-win-numbers-txt">3 match</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-status">*/}
      {/*            <p className="nr-raffle-status-txt">Loose</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className="nr-raffle-content-row">*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-participants">*/}
      {/*            <div className="nr-raffle-participants-icon">*/}
      {/*                H*/}
      {/*            </div>*/}
      {/*            <div className="nr-raffle-participants-info">*/}
      {/*                <p className="nr-raffle-participants-name">Garush</p>*/}
      {/*                <p className="nr-raffle-participants-id">ID 498593</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-lucky-numbers-holder">*/}
      {/*            <div className="nr-raffle-lucky-numbers active">*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">6</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">2</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">4</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">8</p>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*            <div className="nr-raffle-status-mobile win">*/}
      {/*                <p className="nr-raffle-status-mobile-txt">AED 10.000</p>*/}
      {/*                <div className="nr-raffle-status-divider"/>*/}
      {/*                <p className="nr-raffle-status-mobile-txt">Win</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-win-numbers active">*/}
      {/*            <p className="nr-raffle-win-numbers-txt">3 match</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-status win">*/}
      {/*            <p className="nr-raffle-status-txt">AED 10.000</p>*/}
      {/*            <div className="nr-raffle-status-divider"/>*/}
      {/*            <p className="nr-raffle-status-txt">Win</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className="nr-raffle-content-row">*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-participants">*/}
      {/*            <div className="nr-raffle-participants-icon">*/}
      {/*                H*/}
      {/*            </div>*/}
      {/*            <div className="nr-raffle-participants-info">*/}
      {/*                <p className="nr-raffle-participants-name">Maretta</p>*/}
      {/*                <p className="nr-raffle-participants-id">ID 498593</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-lucky-numbers-holder">*/}
      {/*            <div className="nr-raffle-lucky-numbers active">*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">6</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">2</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number active">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">3</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">4</p>*/}
      {/*                </div>*/}
      {/*                <div className="nr-raffle-lucky-number">*/}
      {/*                    <p className="nr-raffle-lucky-number-txt">8</p>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-win-numbers active">*/}
      {/*            <p className="nr-raffle-win-numbers-txt">3 match</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="nr-raffle-info-col">*/}
      {/*        <div className="nr-raffle-status">*/}
      {/*            <p className="nr-raffle-status-txt">Loose</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};
export default RaffleHome;
